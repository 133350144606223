import React, { useEffect, useRef, useContext, useState } from "react";
import ReactGA from "react-ga";
import classnames from "classnames";
import { BeforeInstallPromptEvent } from "../../typings";
import "../styles/PromptListener.css";
import Icon from "./Icon";
import { CloseIcon } from "../icons";
// @ts-ignore
import * as ringsImg from "../images/rings-192.png";
import { isSafari as isSafariHelper } from "../helpers/isSafari";
import PromptContext from "../context/PromptContext";
import { useEffectOnce } from "react-use";

function PromptListener() {
  const deferredPromptRef = useRef<BeforeInstallPromptEvent | null>(null);
  const { isPromptVisible, setPromptVisibility } = useContext(PromptContext);
  const [isSafari, setSafariState] = useState<boolean | null>(null);

  function handleClick() {
    setPromptVisibility(false);
    deferredPromptRef.current!.prompt();

    deferredPromptRef.current!.userChoice.then(choiceResult => {
      if (choiceResult.outcome === "accepted") {
        ReactGA.event({
          action: "Accept A2HS prompt",
          category: "Application",
          label: "Accept A2HS prompt"
        });
      } else {
        ReactGA.event({
          action: "Decline A2HS prompt",
          category: "Application",
          label: "Decline A2HS prompt"
        });
      }

      deferredPromptRef.current = null;
    });
  }

  function handleClose() {
    setPromptVisibility(false);

    ReactGA.event({
      action: "Close A2HS banner",
      category: "Application",
      label: "Close A2HS banner"
    });
  }

  useEffectOnce(() => {
    setSafariState(isSafariHelper());
  });

  useEffect(() => {
    function handleInstallPrompt(e: BeforeInstallPromptEvent) {
      e.preventDefault();
      deferredPromptRef.current = e;
      setPromptVisibility(true);

      ReactGA.event({
        action: "Display A2HS banner",
        category: "Application",
        label: "Display A2HS banner",
        nonInteraction: true
      });
    }

    // @ts-ignore
    window.addEventListener("beforeinstallprompt", handleInstallPrompt);

    return () => {
      //@ts-ignore
      window.removeEventListener("beforeinstallprompt", handleInstallPrompt);
    };
    // eslint-disable-next-line
  }, []);

  if (isSafari) return null;

  return (
    <div
      data-testid="prompt-listener"
      className={classnames("PromptListener", {
        "PromptListener--visible": isPromptVisible
      })}
    >
      <Icon containerWidth={44} title="pwa-icon" src={ringsImg} />
      <button className="PromptListener__text-button" onClick={handleClick}>
        Add Cali Skills to home screen
      </button>
      <button onClick={handleClose} className="PromptListener__close-button">
        <Icon containerWidth={30} title="close-banner" src={CloseIcon} />
      </button>
    </div>
  );
}

export default PromptListener;
