interface Body {
  name: string;
  email?: string;
}

function createUser(accessToken: string, body: Body) {
  return fetch(`${process.env.GATSBY_SERVER_URL}/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(body)
  });
}

export default createUser;
