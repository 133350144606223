import React, { forwardRef } from "react";
import classnames from "classnames";
import "../styles/Button.css";

type Props = {
  children: React.ReactNode;
  handleClick?: VoidFunction;
  testId?: string;
  colorVariant?: "light" | "dark";
  size?: "large" | "standard" | "small";
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
};

const defaultProps: Partial<Props> = {
  size: "standard",
  type: "button",
  colorVariant: "dark"
};

const defaultFunction = () => null;

const Button = forwardRef((props: Props, ref: React.Ref<HTMLButtonElement>) => {
  const {
    children,
    testId,
    handleClick = defaultFunction,
    size,
    disabled,
    type,
    colorVariant
  } = props;

  return (
    <button
      data-testid={`${testId}-button`}
      onClick={() => {
        if (disabled) return null;

        return handleClick();
      }}
      type={type}
      ref={ref}
      className={classnames(
        `Button hover-sheet Button--${size} Button--${colorVariant}`,
        {
          "Button--disabled": disabled
        }
      )}
    >
      <p
        className={`Button__text Button__text--${size} Button__text--${colorVariant}`}
      >
        {children}
      </p>
    </button>
  );
});

Button.defaultProps = defaultProps;

export default Button;
