import React from "react";
import { ReactQueryDevtools } from "react-query-devtools";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import "typeface-roboto";
import "typeface-rubik";
import { AuthProvider } from "./src/context/AuthContext";
import { PromptProvider } from "./src/context/PromptContext";
import { SheetProvider } from "./src/context/SheetContext";
import { UserProvider } from "./src/context/UserContext";
import { WorkoutProvider } from "./src/context/WorkoutContext";
import PromptListener from "./src/components/PromptListener";
import HTMLHead from "./src/components/HTMLHead";
import NetworkStatusListener from "./src/components/listeners/NetworkStatusListener";
import TokenRenewal from "./src/components/TokenRenewal";
import GDPRComplianceCheck from "./src/components/GDPRComplianceCheck";
import Notifications from "./src/components/FirebaseNotifications";
import CookieConsentForm from "./src/components/CookieConsentForm";
import "./src/styles/App.css";
import "./src/styles/Forms.css";
import "./src/styles/Loader.css";
import { version } from "./package.json";

ReactGA.initialize(process.env.GATSBY_GA_KEY);
ReactGA.set({ anonymize: true });

ReactGA.event({
  category: "Application",
  action: `Version: ${version}`,
  label: `Using Calisthenics Skills v${version}`,
  nonInteraction: true
});

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_URL,
  integrations: [
    new Integrations.CaptureConsole({
      levels: ["error"]
    })
  ]
});

const portal = document.createElement("div");
portal.id = "modal-root";
document.body.parentNode.insertBefore(portal, document.body.nextSibling);

export const onRouteUpdate = (state, page, pages) => {
  ReactGA.pageview(state.location.pathname);
};

export const wrapRootElement = ({ element }) => {
  return (
    <AuthProvider>
      <HTMLHead />
      <div className="App" data-testid="app">
        <GDPRComplianceCheck />
        <PromptProvider>
          <SheetProvider>
            <UserProvider>
              <Notifications />
              <PromptListener />
              <NetworkStatusListener />
              <TokenRenewal />
              <WorkoutProvider>{element}</WorkoutProvider>
              <CookieConsentForm />
            </UserProvider>
          </SheetProvider>
        </PromptProvider>
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </AuthProvider>
  );
};
