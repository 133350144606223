import { Machine, assign } from "xstate";

type WorkoutProgress = {
  exerciseId: string;
  progress: number[];
}[];

interface WorkoutStateSchema {
  states: {
    workoutIntro: {};
    warmup: {};
    exercise: {};
    rest: {};
    workoutSummary: {};
  };
}

export type WorkoutEvent =
  | { type: "START_WORKOUT" }
  | { type: "NEXT_WARMUP" }
  | { type: "NEXT_WORKOUT_BLOCK" }
  | {
      type: "INIT_EXERCISE";
      value: { exerciseId: string; defaultProgress: number[] };
    }
  | { type: "UPDATE_PROGRESS"; value: { exerciseId: string; progress: number } }
  | {
      type: "UPDATE_MAX_PROGRESS";
      value: { exerciseId: string; progress: number };
    }
  | { type: "NEXT_EXERCISE" }
  | { type: "START_REST" }
  | { type: "FINISH_WORKOUT" };

export interface WorkoutContext {
  currentSet: number;
  currentWorkoutBlockIndex: number;
  workoutProgress: WorkoutProgress;
  currentExerciseId: string | null;
  newMaxProgress: Record<string, number>;
}

const nextSet = assign<WorkoutContext>({
  currentSet: context => context.currentSet + 1
});

const nextWorkoutBlock = assign<WorkoutContext>({
  currentWorkoutBlockIndex: context => context.currentWorkoutBlockIndex + 1,
  currentSet: () => 0
});

const initExercise = assign<WorkoutContext, WorkoutEvent>({
  currentExerciseId: (context, event) => {
    if (event.type !== "INIT_EXERCISE") return context.currentExerciseId;

    return event.value.exerciseId;
  }
});

const initProgress = assign<WorkoutContext, WorkoutEvent>({
  workoutProgress: (context, event) => {
    if (event.type !== "INIT_EXERCISE") return context.workoutProgress;

    const { workoutProgress } = context;
    const { exerciseId, defaultProgress } = event.value;

    const currentExerciseIndex = workoutProgress.findIndex(
      e => exerciseId === e.exerciseId
    );

    if (currentExerciseIndex !== -1) {
      return workoutProgress;
    }

    workoutProgress.push({
      exerciseId,
      progress: defaultProgress
    });

    return workoutProgress;
  }
});

const updateProgress = assign<WorkoutContext, WorkoutEvent>({
  workoutProgress: (context, event) => {
    if (event.type !== "UPDATE_PROGRESS") return context.workoutProgress;

    const { currentSet, workoutProgress } = context;
    const { exerciseId, progress } = event.value;

    const currentExerciseIndex = workoutProgress.findIndex(
      e => exerciseId === e.exerciseId
    );

    workoutProgress[currentExerciseIndex].progress[currentSet] = progress;

    return workoutProgress;
  }
});

const updateMaxProgress = assign<WorkoutContext, WorkoutEvent>({
  newMaxProgress: (context, event) => {
    if (event.type !== "UPDATE_MAX_PROGRESS") return context.newMaxProgress;

    const { exerciseId, progress } = event.value;

    return {
      ...context.newMaxProgress,
      [exerciseId]: progress
    };
  }
});

const finishWorkout = assign<WorkoutContext, WorkoutEvent>({
  currentExerciseId: null
});

function WorkoutMachineFactory() {
  const id = new Date().getTime();

  return Machine<WorkoutContext, WorkoutStateSchema, WorkoutEvent>({
    id: String(id),
    initial: "workoutIntro",
    context: {
      currentSet: 0,
      currentWorkoutBlockIndex: 0,
      workoutProgress: [],
      currentExerciseId: null,
      newMaxProgress: {}
    },
    states: {
      workoutIntro: {
        on: {
          NEXT_WARMUP: "warmup"
        }
      },
      warmup: {
        on: {
          NEXT_WORKOUT_BLOCK: "exercise"
        }
      },
      exercise: {
        on: {
          NEXT_EXERCISE: "exercise",
          START_REST: "rest",
          INIT_EXERCISE: {
            target: "exercise",
            actions: [initProgress, initExercise]
          },
          UPDATE_PROGRESS: {
            target: "exercise",
            actions: updateProgress
          },
          UPDATE_MAX_PROGRESS: {
            target: "exercise",
            actions: updateMaxProgress
          }
        }
      },
      rest: {
        on: {
          NEXT_EXERCISE: {
            target: "exercise",
            actions: nextSet
          },
          NEXT_WORKOUT_BLOCK: {
            target: "exercise",
            actions: nextWorkoutBlock
          },
          FINISH_WORKOUT: {
            target: "workoutSummary",
            actions: finishWorkout
          }
        }
      },
      workoutSummary: {
        type: "final"
      }
    }
  });
}

export default WorkoutMachineFactory;
