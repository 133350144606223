interface Body {
  registrationTokens: Array<string | null>;
}

function subscribeUserToNotifications(body: Body) {
  fetch(
    "https://ffcmhqcjz7.execute-api.eu-west-2.amazonaws.com/Production/subscribe",
    {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    }
  );
}

export default subscribeUserToNotifications;
