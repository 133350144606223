import React, { createContext, useReducer, useEffect } from "react";
import { UserData, State } from "../models";
import { createReducer } from "../hooks/reducerFactories";
import getUser from "../api/getUser";
import useAuth from "../hooks/useAuth";

interface Props {
  children: React.ReactNode;
}

type IUserContext = State<UserData>;

const initialState = {
  loading: true,
  error: null,
  data: null
};

const UserContext = createContext<IUserContext>(initialState);

const reducer = createReducer<UserData>();

export function UserProvider(props: Props) {
  const {
    getUserId,
    getAccessToken,
    tokenRenewalComplete,
    getAuthenticationState
  } = useAuth();

  const [state, dispatch] = useReducer(reducer, initialState);
  const { data, error, loading } = state;

  const isLoggedIn = getAuthenticationState();

  useEffect(() => {
    if (!tokenRenewalComplete) return;

    if (!isLoggedIn) {
      return dispatch({
        type: "NOT_LOGGED_IN",
        payload: {
          data: null,
          error: null
        }
      });
    }

    getUser(getUserId(), getAccessToken())
      .then(async res => {
        const data = await res.json();

        if (data.error) {
          return dispatch({
            type: "ERROR_RECEIVED",
            payload: {
              error: data.error,
              data: null
            }
          });
        }

        return dispatch({
          type: "RESULT_RECEIVED",
          payload: {
            data: {
              firstName: data.first_name ?? "",
              lastName: data.last_name ?? "",
              gender: data.gender ?? "",
              dateOfBirth: data.date_of_birth
                ? new Date(data.date_of_birth)
                : null,
              email: data.email,
              planType: data.isSubscriptionActive ? "premium" : "free"
            },
            error: null
          }
        });
      })
      .catch(err => {
        return dispatch({
          type: "ERROR_RECEIVED",
          payload: {
            error: err,
            data: null
          }
        });
      });
  }, [isLoggedIn, tokenRenewalComplete]);

  return (
    <UserContext.Provider
      value={{
        loading,
        error,
        data
      }}
    >
      <>{props.children}</>
    </UserContext.Provider>
  );
}

export default UserContext;
