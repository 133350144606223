import ReactGA from "react-ga";
import firebase from "firebase/app";
import "@firebase/messaging";
import { useEffectOnce } from "react-use";
import subscribeUserToNotifications from "../api/subscribeUserToNotifications";

const firebaseConfig = {
  apiKey: process.env.GATSBY_FB_API_KEY,
  authDomain: "calisthenics-skills-messaging.firebaseapp.com",
  databaseURL: "https://calisthenics-skills-messaging.firebaseio.com",
  projectId: "calisthenics-skills-messaging",
  storageBucket: "",
  messagingSenderId: "823352499493",
  appId: process.env.GATSBY_FB_APP_ID
};

firebase.initializeApp(firebaseConfig);

function Notifications() {
  function handleMessaging() {
    const isSupported = firebase.messaging.isSupported();

    if (isSupported) {
      const messaging = firebase.messaging();
      messaging.usePublicVapidKey(process.env.GATSBY_PUBLIC_VAPID_KEY);
      messaging
        .requestPermission()
        .then(() => {
          ReactGA.event({
            category: "Notification",
            action: "Accept permission",
            label: "Application has permission to display notifications"
          });

          return messaging.getToken();
        })
        .then(token => {
          const data = {
            registrationTokens: [token]
          };

          return subscribeUserToNotifications(data);
        })
        .then(res => {
          ReactGA.event({
            category: "Notification",
            action: "Registered to topic",
            label:
              "The user has been successfully registered to the update topic",
            nonInteraction: true
          });

          return res;
        })
        .catch(() => {
          ReactGA.event({
            category: "Notification",
            action: "Error registering to topic",
            label:
              "There was an error registering the user to the update topic",
            nonInteraction: true
          });

          // enable this when i don't have to worry about my sentry count
          // console.error("err", err);
        });
    } else {
      ReactGA.event({
        category: "Notification",
        action: "Unable to initialise analytics",
        label: "Browser not supported",
        nonInteraction: true
      });
    }
  }

  useEffectOnce(() => {
    setTimeout(() => {
      handleMessaging();
    }, 30000);
  });

  return null;
}

export default Notifications;
