import React, { createContext, useState, useEffect } from "react";
import WorkoutMachine, {
  WorkoutEvent
} from "../components/Workout/state/workoutMachine";
import { Workout } from "../components/Workout";
import { useMachine } from "@xstate/react";
import exercises from "../data/exercises/exercises";
import EXERCISE_KEYS from "../data/exerciseKeys";

export type WorkoutState = typeof initialState;
type WorkoutMachine = typeof initialMachine;
type SendFunction = (type: WorkoutEvent) => void;

interface Meta {
  groupPosition: number;
  exercisePosition: number;
}

interface IWorkoutContext {
  state: WorkoutState;
  send: (type: WorkoutEvent) => void;
  workout: Workout | null;
  updateWorkout: (exerciseId: string, meta: Meta) => void;
  startWorkout: (workout: Workout) => void;
  endWorkout: () => void;
}

interface Props {
  children: React.ReactNode;
}

const WorkoutContext = createContext<IWorkoutContext>({
  state: {} as WorkoutState,
  send: () => null,
  workout: null,
  updateWorkout: () => null,
  startWorkout: () => null,
  endWorkout: () => null
});

const initialMachine = WorkoutMachine();

const { initialState } = initialMachine;

export function WorkoutProvider({ children }: Props) {
  const [machine, setMachine] = useState(initialMachine);
  const [workout, setWorkout] = useState<Workout | null>(null);

  useEffect(() => {
    function preventExitWhenWorkoutInProgress(e: BeforeUnloadEvent) {
      if (workout) {
        e.preventDefault();
        e.returnValue = "";
      }
    }

    window.addEventListener(
      "beforeunload",
      preventExitWhenWorkoutInProgress,
      false
    );

    return function () {
      window.removeEventListener(
        "beforeunload",
        preventExitWhenWorkoutInProgress
      );
    };
  }, [workout]);

  const endWorkout = () => setWorkout(null);

  const startWorkout = (workout: Workout) => {
    const newMachine = WorkoutMachine();
    setMachine(newMachine);
    return setWorkout(workout);
  };

  const updateWorkout = (newExerciseId: string, meta: Meta) => {
    const { groupPosition, exercisePosition } = meta;

    if (!workout) return null;

    const clonedWorkout = { ...workout };

    const currentBlock = clonedWorkout.workoutBlocks[groupPosition];
    const currentExercise = currentBlock.exercises[exercisePosition];

    const { title, contractionType } = exercises[
      newExerciseId as EXERCISE_KEYS
    ];

    const newExercise = {
      title,
      exerciseId: newExerciseId,
      contractionType,
      repCounts: currentExercise.repCounts,
      repCount: currentExercise.repCount
    };

    clonedWorkout.workoutBlocks[groupPosition].exercises[
      exercisePosition
    ] = newExercise;

    return setWorkout(clonedWorkout);
  };

  return (
    <MachineController machine={machine} key={machine.id}>
      {(state, send) => {
        return (
          <WorkoutContext.Provider
            value={{
              state,
              send,
              workout,
              startWorkout,
              updateWorkout,
              endWorkout
            }}
          >
            {children}
          </WorkoutContext.Provider>
        );
      }}
    </MachineController>
  );
}

export default WorkoutContext;

// this is used to handle creating a new instance of the workout machine
// that way the progress resets when a user create a brand new workout after completing a previous.
interface MachineControllerProps {
  children: (state: WorkoutState, send: SendFunction) => React.ReactNode;
  machine: WorkoutMachine;
}

function MachineController(props: MachineControllerProps) {
  const [state, send] = useMachine(props.machine);

  return <>{props.children(state, send)}</>;
}
