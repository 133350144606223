import { useEffect } from "react";
import generateSnackbar from "../../helpers/generateSnackbar";

function NetworkStatusListener() {
  useEffect(() => {
    function handleOnline() {
      generateSnackbar({
        message:
          "You're back online! You can use Cali Skills as normal",
        type: "success"
      });
    }

    function handleOffline() {
      generateSnackbar({
        message:
          "You've lost internet connection. You may experience reduced functionality",
        type: "warn"
      });
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return null;
}

export default NetworkStatusListener;
