import React from "react";
import EXERCISE_KEYS from "../exerciseKeys";
import { Link } from "gatsby";
import {
  PullUpIcon,
  PistolSquatIcon,
  SquatIcon,
  HumanFlagIcon,
  PlankIcon,
  DeepStepUpIcon,
  CrowPoseIcon,
  ElbowLeverIcon,
  LSitIcon,
  FrontLeverIcon,
  HandstandIcon,
  ToesToBarIcon,
  HollowHoldIcon,
  PlancheIcon,
  BackLeverIcon,
  PushUpIcon,
  ParallelSquatIcon,
  ParallelBarDipIcon,
  BarSupportHoldIcon,
  CossackSquat,
  DragonFlagIcon,
  NordicCurlIcon,
  WallHandstandIcon,
  RowIcon,
  MuscleUpIcon,
  PikePushUpIcon,
  PikeSkinTheCatIcon,
  WheelIcon
} from "../../exerciseIcons";
import { Contraction } from "../../models";

interface Exercise {
  title: string;
  exerciseId: string;
  description: JSX.Element | string;
  contractionType: Contraction;
  resourceUrl: string;
  icon: string;
}

const exercises: Record<EXERCISE_KEYS, Exercise> = {
  [EXERCISE_KEYS.ARCHER_PULL_UP]: {
    title: "Archer Pull Up",
    description:
      "A variation of the pull up that focuses more on a single side of your body per rep. The starting position requires a wide overhand grip, with arms much wider than shoulder-width. Begin the movement by your weight more on your left-hand side and exerting more pulling effort with the left arm. Your left pec should reach the left arm with your right arms providing support. When at the top of the movement, the arm supporting arm should be nearly straight, and parallel to the ground. Slowly reverse the movement to come back to the start position, and switch the dominant arms. ",
    exerciseId: EXERCISE_KEYS.ARCHER_PULL_UP,
    resourceUrl: "https://youtu.be/LvekuNbCrFc",
    contractionType: "isotonic",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.ARCHER_PUSH_UP]: {
    title: "Archer Push Up",
    description:
      "Start the movement in Plank, but with your arms wide. Shift your weight toward the left arm. Keep core and legs tight and start descending, keeping your right arm straight the whole time while bending the left arm. Use your right arm for support, when your chest grazes the ground, push up with your left arm and come back to starting. Swap arms between reps.",
    exerciseId: EXERCISE_KEYS.ARCHER_PUSH_UP,
    resourceUrl: "https://youtu.be/A0r8ploEnZY?t=36",
    contractionType: "isotonic",
    icon: PushUpIcon
  },
  [EXERCISE_KEYS.ARCH_BODY_HOLD]: {
    title: "Arch Body Hold",
    description:
      "Lie face down on the ground with your body straight and your arms out ahead of you, think Superman flying. Enter the hold by lifting your upper body off the ground.  Focus your attention on your legs and bring them off the ground too. Ensure that your knees don't bend and that your legs remain straight throughout the duration of the hold.",
    exerciseId: EXERCISE_KEYS.ARCH_BODY_HOLD,
    resourceUrl: "https://www.youtube.com/embed/jelLnjPq4ck",
    contractionType: "isometric",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.ARCH_HANG]: {
    title: "Arch Hang",
    description:
      "From the scapula pull, pull the shoulders down even further and have your ribcage point upwards. Arch the back and keep the legs bent behind you. Retract the scapula and try to get the should blades to touch each other. Much like the scapula pull, don't bend the arms to prevent the bicep from assisting. Scapula depression and retraction. ",
    exerciseId: EXERCISE_KEYS.ARCH_HANG,
    resourceUrl: "https://www.youtube.com/embed/C995b3KLXS4",
    contractionType: "isometric",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.ASSISTED_PISTOL_SQUAT]: {
    title: "Pistol Squat (Assisted)",
    description:
      "Stand shoulder-width apart. Raise your leg left off of the ground in front of you as you squat down with your right leg. Use a pole to help maintain balance and assistance as you lower your body down and come back up again. Repeat with your left leg. ",
    exerciseId: EXERCISE_KEYS.ASSISTED_PISTOL_SQUAT,
    resourceUrl: "https://youtu.be/SEi1iUMMZKg?t=227",
    contractionType: "isotonic",
    icon: PistolSquatIcon
  },
  [EXERCISE_KEYS.ASSISTED_SQUAT]: {
    title: "Assisted Squat",
    description: (
      <p>
        The Assisted Squat is a regression for those that struggle with
        executing a Bodyweight Squat with full range of motion. A steady surface
        is used to rest your hands in front of your body. When performing the
        exercise, bring your hips as low as is comfortable. This less-difficult
        version will slowly improve your strength so you can gradually perform
        the Squat deeper, and with less assistance.{" "}
        <Link to="/exercises/bodyweight-squat/">
          Learn how to perform the Squat
        </Link>
        .
      </p>
    ),
    exerciseId: EXERCISE_KEYS.ASSISTED_SQUAT,
    resourceUrl: "https://www.youtube.com/watch?v=slR0Nweib34",
    contractionType: "isotonic",
    icon: ParallelSquatIcon
  },
  [EXERCISE_KEYS.BACK_LEVER]: {
    title: "Back Lever",
    description:
      "Begin the exercise in the same way as you performed your Straddle Back Lever Hold. Once in the horizontal position bring your legs together and extend them out fully, so that your body becomes a straight line. Keep core, legs and glutes tight and your arms locked out.",
    exerciseId: EXERCISE_KEYS.BACK_LEVER,
    resourceUrl: "https://www.youtube.com/embed/SECHUBj7pl0?start=30",
    contractionType: "isometric",
    icon: BackLeverIcon
  },
  [EXERCISE_KEYS.BACK_LEVER_NEGATIVE]: {
    title: "Back Lever (Negastive)",
    description:
      "Begin the exercise in an Inverted Hang with your legs straight, and your calves touching the bar. Keeping your whole body straight and engaged, slowly lower your body into a Back Lever, and carry on until you reach a German Hang.",
    exerciseId: EXERCISE_KEYS.BACK_LEVER,
    resourceUrl: "https://www.youtube.com/embed/SECHUBj7pl0?start=30",
    contractionType: "isotonic",
    icon: BackLeverIcon
  },
  [EXERCISE_KEYS.BAND_ASSISTED_OA_PULL_UP]: {
    title: "Band Assisted One-Armed Pull Up",
    description:
      "This is a variation of the uneven Pull Up. The Pull Up is adjusted to make one arm perform more work than the other. Tie a resistance band around the bar. Perform a Pull Up with one hand holding the band while the other holds the bar. The bigger the height difference between your hands, the more difficult the exercise. The lighter the band, the more difficult the exercise.",
    exerciseId: EXERCISE_KEYS.BAND_ASSISTED_OA_PULL_UP,
    resourceUrl: "https://youtu.be/vdjWgw98EeI?t=145",
    contractionType: "isotonic",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.BANDED_PALOFF_PRESS]: {
    title: "Banded Paloff Press",
    description:
      "Attach a resistance band at chest height to a fixed post. With a distance of 6ft, face the post with your arms forward and turn your body 90 degrees clockwise. Holding the band, move your fist to and away from your chest. Do this in a slow and controlled manner and ensure that your core is engaged and not twisting. Avoid any rotation at the hip or shoulder. Repeat for both sides of the body.",
    exerciseId: EXERCISE_KEYS.BANDED_PALOFF_PRESS,
    resourceUrl: "https://youtu.be/AH_QZLm_0-s?t=6",
    contractionType: "isotonic",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.BAR_DIP]: {
    title: "Parallel Bar Dip",
    description: (
      <p>
        The Parallel Bar Dip, also known as the Tricep Dip, is a tricep
        exercises that also works the shoulders and chest. Because Parallel Bar
        Dips are a compound upper-body pushing exercise, they're a great tool to
        create more powerful arms.{" "}
        <Link to="/exercises/parallel-bar-dip/">
          Learn how to perform the Parallel Bar Dip
        </Link>
        .
      </p>
    ),
    exerciseId: EXERCISE_KEYS.BAR_DIP,
    resourceUrl: "https://youtu.be/yN6Q1UI_xkE?t=173",
    contractionType: "isotonic",
    icon: ParallelBarDipIcon
  },
  [EXERCISE_KEYS.BAR_SUPPORT_HOLD]: {
    title: "Bar Support Hold",
    description:
      "Using a pair of parallel bars, grip a bar with each hand. Use a box to help you get into the starting position where the bars are level with your hips. With a firm grip, off the box so your arms are fully supporting your body. Engage your core and remain in a hollow body position. Ensure that your arms are straight and that you're pulling your shoulders back. Hold this static position for as long as possible. When finished, slowly bend your arms until your feet touch the floor, or step back on to the box.",
    exerciseId: EXERCISE_KEYS.BAR_SUPPORT_HOLD,
    resourceUrl: "https://www.youtube.com/watch?v=IbWE49HdsrE",
    contractionType: "isometric",
    icon: BarSupportHoldIcon
  },
  [EXERCISE_KEYS.BEGINNER_SHRIMP_SQUAT]: {
    title: "Beginner Shrimp Squat",
    description:
      "Place a box behind you and bend your right leg so it's at a right-angle parallel to the ground. Slowly squat until your knee touches the box. The shorter the surface, the more challenging the exercise.",
    exerciseId: EXERCISE_KEYS.BEGINNER_SHRIMP_SQUAT,
    resourceUrl: "https://www.youtube.com/watch?v=Dpgn6eRtsdw",
    contractionType: "isotonic",
    icon: SquatIcon
  },
  [EXERCISE_KEYS.BULGARIAN_DIP]: {
    title: "Bulgarian Dip",
    description:
      "Also known as the Wide Dips. Place the rings so that they're wider than shoulder width apart. Perform the concentric portion of the movement like normal, but as you descend slowly turn your hands inward. When you reach the bottom of the exercise your arms should be at a 90 degree angle. ",
    exerciseId: EXERCISE_KEYS.BULGARIAN_DIP,
    resourceUrl: "https://youtu.be/iC2LLN7cuFs?t=6",
    contractionType: "isotonic",
    icon: ParallelBarDipIcon
  },
  [EXERCISE_KEYS.BULGARIAN_SPLIT_SQUAT]: {
    title: "Bulgarian Split Squat",
    description:
      "Similar to the split squat but with your back foot on an elevated surface. Ensure that your front leg isn't much further away from you, only a few inches in front of your hip. Lower your body until your back knee grazes the ground and push back up with your front leg. ",
    exerciseId: EXERCISE_KEYS.BULGARIAN_SPLIT_SQUAT,
    resourceUrl: "https://youtu.be/Ksz5rkmBuTA?t=112",
    contractionType: "isotonic",
    icon: SquatIcon
  },
  [EXERCISE_KEYS.CHAMBER_HOLD]: {
    title: "Chamber Hold",
    description:
      "Two horizontal bars placed above one another are needed for this exercise, which will need to be at a distance slightly less than the wingspan of your arms. Grab the top bar with your left had in a pronated grip, and in the same horizontal position on the bottom bar, grip it with your right arm in a supinated grip. The left hand will be used to pull the body, while the right arm will be used to push the body. While keeping arms straight, kick your body into the air and lift hips into the air and keep knees tucked into the chest. The higher your hips are, the easier the exercise is. Hold the position, then perform again but with the hands switching positions. ",
    exerciseId: EXERCISE_KEYS.CHAMBER_HOLD,
    resourceUrl: "",
    contractionType: "isometric",
    icon: HumanFlagIcon
  },
  [EXERCISE_KEYS.CHEST_TO_BAR_PULL_UP]: {
    title: "Chest to Bar Pull Up",
    description:
      "A variation of the pull up the focuses on pulling the bar from the top of your chest, to the bottom of the chest, and progressions include pulling the bar to even lower. The main difference in execution is the positioning of the arms. Protract the shoulders forward, and make sure that the arms aren't straight above your head. Instead aim on keeping your arms pointed in front of your. Prepare the pull by first performing a Chamber Hold, then complete the Pull-up in a controlled motion.",
    exerciseId: EXERCISE_KEYS.CHEST_TO_BAR_PULL_UP,
    resourceUrl: "https://www.youtube.com/embed/Ww-gNf0ys8M?start=206",
    contractionType: "isotonic",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.COPENHAGEN_PLANK]: {
    title: "Copenhagen Plank",
    description:
      "Get into a side plank position. Adjust the exercise by lifting your lower leg off the ground and bending the knee. Hold this position for time. Ensure that the core is braced tight and the glutes are engaged.",
    exerciseId: EXERCISE_KEYS.COPENHAGEN_PLANK,
    resourceUrl: "https://youtu.be/1NNqUQvMYGc?t=5",
    contractionType: "isometric",
    icon: PlankIcon
  },
  [EXERCISE_KEYS.COSSACK_SQUAT]: {
    title: "Cossack Squat",
    description:
      "The starting position of the movement is a very wide leg stance, twice shoulder-width. While keeping your left leg straight, squat down with your right leg as far as you're able to. Push upwards back to the starting position. Repeat with the other leg. ",
    exerciseId: EXERCISE_KEYS.COSSACK_SQUAT,
    resourceUrl: "https://youtu.be/rwXAYuwZH9o?t=118",
    contractionType: "isotonic",
    icon: CossackSquat
  },
  [EXERCISE_KEYS.CRANE_POSE]: {
    title: "Crane Pose",
    description:
      "Performed similarly to the Crow Pose, but with straight arms. Rest your knees on your arms for support and keep balanced using your hands.",
    exerciseId: EXERCISE_KEYS.CRANE_POSE,
    resourceUrl: "https://youtu.be/Gi6LfJoNUjQ",
    contractionType: "isometric",
    icon: CrowPoseIcon
  },
  [EXERCISE_KEYS.CROW_POSE]: {
    title: "Crow Pose",
    description: (
      <p>
        For most people, the Crow Pose is the gateway into inversions and arm
        balances. The Crow Pose is performed so your body is held up off the
        ground using your hands, with your knees resting on your triceps.{" "}
        <Link to="/exercises/crow-pose/">
          Learn how to perform the Crow Pose
        </Link>
        .
      </p>
    ),

    exerciseId: EXERCISE_KEYS.CROW_POSE,
    resourceUrl: "https://youtu.be/xEoaoes9f7k?t=113",
    contractionType: "isometric",
    icon: CrowPoseIcon
  },
  [EXERCISE_KEYS.DEEP_STEP_UP]: {
    title: "Deep Step Up",
    description:
      "Using a plyobox (or equivalent equipment), position so the top surface is level with the middle of your thigh. Place your feet 6 inches away from the base of the box. With your left leg, step on to the box applying pressure on to your left foot to lift your body on to the box. Try not to use momentum form your back leg to assist in getting on to the box.",
    exerciseId: EXERCISE_KEYS.DEEP_STEP_UP,
    resourceUrl: "https://youtu.be/bVRLKMEKOmo",
    contractionType: "isotonic",
    icon: DeepStepUpIcon
  },
  [EXERCISE_KEYS.DIAMOND_PUSH_UP]: {
    title: "Diamond Push Up",
    description:
      "Perform a movement identical to the Push-up, but with your hands placed close together, forming a diamond shape with your thumb and index finger. This emphasizes the tricep muscles during the exercise.",
    exerciseId: EXERCISE_KEYS.DIAMOND_PUSH_UP,
    resourceUrl: "https://youtu.be/XtU2VQVuLYs",
    contractionType: "isotonic",
    icon: PushUpIcon
  },
  [EXERCISE_KEYS.DRAGON_FLAG]: {
    title: "Dragon Flag",
    description: (
      <p>
        Learn one of the most ferocious ab-burners, the{" "}
        <Link to="/exercises/dragon-flag">Dragon Flag</Link>. The Dragon Flag is
        a core exercise that works your whole body, and provides crossover to
        other more advanced calisthenics exerices.{" "}
        <Link to="/exercises/dragon-flag/">
          Learn how to perform the Dragon Flag
        </Link>
        .
      </p>
    ),
    exerciseId: EXERCISE_KEYS.DRAGON_FLAG,
    resourceUrl: "https://youtu.be/DJnX__TFB9o?t=28",
    contractionType: "isometric",
    icon: DragonFlagIcon
  },
  [EXERCISE_KEYS.ELBOW_LEVER]: {
    title: "Elbow Lever",
    description:
      "Begin the exercise in the same way as the Straddle Elbow Lever, but as your body line becomes straight, bring your feet together. ",
    exerciseId: EXERCISE_KEYS.ELBOW_LEVER,
    resourceUrl: "https://www.youtube.com/embed/ZNCFXIIB0eQ?start=166",
    contractionType: "isometric",
    icon: ElbowLeverIcon
  },
  [EXERCISE_KEYS.EXTENDED_PLANK]: {
    title: "Extended Plank",
    description:
      "A challenging variation on the Plank. Begin in the Plank position, and start slowly moving your arms out in front of you. Don't let your hips sink, keep your core engaged, and maintain a hollow body throughout. Ther further out your arms are, the more difficult the exercise.",
    exerciseId: EXERCISE_KEYS.EXTENDED_PLANK,
    resourceUrl: "https://youtu.be/Tu_byYugBi0?t=112",
    contractionType: "isometric",
    icon: PlankIcon
  },
  [EXERCISE_KEYS.FOOT_SUPPORTED_LSIT]: {
    title: "Supported L-Sit",
    description:
      "Sit down on the floor with your legs stretched out straight in front. Protract your shoulders and plant your hands on the ground by your waist facing straight ahead. Push down on the floor with your hands until your butt comes off the floor and your elbows are locked out. Keep the feet on the floor and hold this isometric hold for as long as possible. Performing this exercise with parallettes may make for an easier alternative if performing on the floor is too challenging.",
    exerciseId: EXERCISE_KEYS.FOOT_SUPPORTED_LSIT,
    resourceUrl: "https://www.youtube.com/watch?v=3ipRegcjoXc",
    contractionType: "isometric",
    icon: LSitIcon
  },
  [EXERCISE_KEYS.FORTY_FIVE_DEG_NORDIC_CURL]: {
    title: "45° Hip Nordic Curl",
    description:
      "Perform the same movement as the Nordic Curl, with your hip bent at 45°. This shortens the lever and reduces the difficulty of the exercise.",
    exerciseId: EXERCISE_KEYS.FORTY_FIVE_DEG_NORDIC_CURL,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: NordicCurlIcon
  },
  [EXERCISE_KEYS.FORTY_FIVE_DEG_VSIT]: {
    title: "45° V-Sit",
    description:
      "Start the position in the L-sit, with your legs parallel with the ground. Engage your core and pull your legs toward the ceiling so that angle between your legs and the floor are at 45°.",
    exerciseId: EXERCISE_KEYS.FORTY_FIVE_DEG_VSIT,
    resourceUrl: "https://youtu.be/3oInqNG0zYw?t=339",
    contractionType: "isometric",
    icon: LSitIcon
  },
  [EXERCISE_KEYS.FRONT_LEVER]: {
    title: "Front Lever",
    description: (
      <p>
        The Front Lever requires a high-degree of back and core strength. Using
        a Pull Up bar, keep your arms straight and perform the Front Lever by
        holding your body horizontal, with your chest facing upward.{" "}
        <Link to="/exercises/front-lever/">
          Learn how to perform the Front Lever
        </Link>
        .
      </p>
    ),
    exerciseId: EXERCISE_KEYS.FRONT_LEVER,
    resourceUrl: "",
    contractionType: "isometric",
    icon: FrontLeverIcon
  },
  [EXERCISE_KEYS.FRONT_LEVER_NEGATIVES]: {
    title: "Front Lever (Negative)",
    description: (
      <p>
        Begin the exercise in an Inverted Hang with your shins touching the bar.
        Ensure that your whole body is engaged and straight. Start lowering your
        body into a Front Lever position, and keep going until your reach a Dead
        Hang.
        <Link to="/exercises/front-lever/">
          Learn how to perform the Front Lever
        </Link>
        .
      </p>
    ),
    exerciseId: EXERCISE_KEYS.FRONT_LEVER_NEGATIVES,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: FrontLeverIcon
  },
  [EXERCISE_KEYS.FRONT_LEVER_RAISES]: {
    title: "Front Lever Raises",
    description: (
      <p>
        Begin the exercise in an Active Hang. Ensure that your whole body is
        engaged and straight. Pull down on the bar or rings with your arms and
        lift your body so that it's in a horizontal Front Lever position for a
        split second. With control, bring your body back into the Active Hang.
        <Link to="/exercises/front-lever/">
          Learn how to perform the Front Lever
        </Link>
        .
      </p>
    ),
    exerciseId: EXERCISE_KEYS.FRONT_LEVER_NEGATIVES,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: FrontLeverIcon
  },
  [EXERCISE_KEYS.FRONT_LEVER_ROW]: {
    title: "Front Lever Row",
    description:
      "The ultimate horizontal back exercise. Begin the exercise in a Front Lever and pull the bar towards you until it reaches your chest. Control the movement back to starting and repeat.",
    exerciseId: EXERCISE_KEYS.FRONT_LEVER_ROW,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: FrontLeverIcon
  },
  [EXERCISE_KEYS.FULL_SQUAT]: {
    title: "Full Squat",
    description: (
      <p>
        <Link to="/exercises/bodyweight-squat/">Squats (or Air Squats)</Link>{" "}
        are one of the most well-known bodyweight exercises and are a
        foundational calisthenics movement. The Squat is a great baseline
        exercise to assess lowerbody health, and should be a regular part of
        your workout routines.{" "}
        <Link to="/exercises/bodyweight-squat">
          Learn how to perform the Squat
        </Link>
        .
      </p>
    ),
    exerciseId: EXERCISE_KEYS.FULL_SQUAT,
    resourceUrl: "https://www.youtube.com/embed/2t3Ab7a2ZM4",
    contractionType: "isotonic",
    icon: SquatIcon
  },
  [EXERCISE_KEYS.GERMAN_HANG]: {
    title: "German Hang",
    description:
      "Bring a pair of rings above head height and hold them with your palms facing towards you. Jump and move into a tucked position, pulling downwards with your arms to help rotate over the rings. When you're upside down, slowly control the rest of the movement until you're at full shoulder extension. Use your feet as support if needed. Resting them on the floor makes the exercise easier, while keeping them in the air makes it more challenging.",
    exerciseId: EXERCISE_KEYS.GERMAN_HANG,
    resourceUrl: "",
    contractionType: "isometric",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.HANDSTAND]: {
    title: "Handstand",
    description: (
      <p>
        A Handstand is not just a major milestone exercise in calisthenics, but
        in a variety of other disciplines, like yoga, gymnastics and
        breakdancing. Performing a perfect handstand requires a lot of practice,
        strength, and a strong awareness of one's body. As such, nailing the
        Handstand is both rewarding and exciting. It's also makes for an
        impressive party trick!{" "}
        <Link to="/exercises/handstand">
          Learn how to perform the Handstand
        </Link>
        .
      </p>
    ),
    exerciseId: EXERCISE_KEYS.HANDSTAND,
    resourceUrl: "https://youtu.be/7uWpM_r9obA?t=1037",
    contractionType: "isometric",
    icon: HandstandIcon
  },
  [EXERCISE_KEYS.HANDSTAND_LEAN_HOLDS]: {
    title: "Handstand Lean Holds",
    description:
      "Begin in a Wall-Assisted Handstand with your chest facing the wall. Move into the Handstand Lean Hold by  bringing your shoulders slightly over your wrists. Hold this position. You don't need to go too far over your wrists, just an inch or two. Ensure good wrist flexibility before-hand. Think of it like an inverted Planche Lean.",
    exerciseId: EXERCISE_KEYS.HANDSTAND_LEAN_HOLDS,
    resourceUrl: "https://youtu.be/OW_ljV5CBKk?t=58",
    contractionType: "isometric",
    icon: WallHandstandIcon
  },
  [EXERCISE_KEYS.HANDSTAND_PRESS]: {
    title: "Handstand Press",
    description:
      "Bring everything you've learned together by performing the Handstand Press without the assistance of a wall. Perform the exercise in the same way. Begin with palms on the floor just in front of your feet. Protract your shoulders and lean them forward until your legs come off the ground. Bring them through the straddle position until they reach together into a Handstand.",
    exerciseId: EXERCISE_KEYS.HANDSTAND_PRESS,
    resourceUrl: "https://youtu.be/R1iCQnAtveU?t=708",
    contractionType: "isotonic",
    icon: HandstandIcon
  },
  [EXERCISE_KEYS.HANDSTAND_PUSH_UP]: {
    title: "Handstand Push Up",
    description:
      "This is the sames as the Wall Assisted Handstand Push Up, but without the assitance of a wall.",
    exerciseId: EXERCISE_KEYS.HANDSTAND_PUSH_UP,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: HandstandIcon
  },
  [EXERCISE_KEYS.HANGING_KNEE_RAISE]: {
    title: "Hanging Knee Raise",
    description:
      "Begin the exercise hanging from a bar. Engage your scapular and hold the hollow body position. Your toes should be pointed forward. Without swinging, pull your knees up toward your chest. Hold briefly, then slowly bring them back to the starting position ",
    exerciseId: EXERCISE_KEYS.HANGING_KNEE_RAISE,
    resourceUrl: "https://youtu.be/lS5B0MmLgZs?t=107",
    contractionType: "isotonic",
    icon: ToesToBarIcon
  },
  [EXERCISE_KEYS.HEADSTAND]: {
    title: "Headstand",
    description:
      "Begin on all fours. Bring your forearms to the ground shoulder-width apart. Interlace your fingers together with your palms facing upward to create a support for your head.  Place the crown of your hand in your hands. Lift your knees off the ground but keep your toes planted. Slowly walk your toes to your hands, trying to get your hips stacked on top of your spine and shoulders. Tuck one leg to your chest, and then the other. Slowly lift your legs and squeeze your thighs together. Ensure that your body is a straight line. ",
    exerciseId: EXERCISE_KEYS.HEADSTAND,
    resourceUrl: "https://www.youtube.com/watch?v=2XczJUXizqU",
    contractionType: "isometric",
    icon: HandstandIcon
  },
  [EXERCISE_KEYS.HEADSTAND_LEG_RAISE]: {
    title: "Headstand Leg Raise",
    description:
      "Begin in a Headstand position. While keeping your legs straight and pushed together, slowly lower them to the ground. Once your toes reach the ground, lift them back up to starting.",
    exerciseId: EXERCISE_KEYS.HEADSTAND_LEG_RAISE,
    resourceUrl: "https://www.youtube.com/watch?v=9QGQilcRFXw",
    contractionType: "isotonic",
    icon: HandstandIcon
  },
  [EXERCISE_KEYS.HEADSTAND_PUSH_UP]: {
    title: "Headstand Push Up",
    description:
      "This is the same as the Wall Assisted Headstand Push Up, but without using a wall, so ensure that you can control a Handstand with good form.",
    exerciseId: EXERCISE_KEYS.HEADSTAND_PUSH_UP,
    resourceUrl: "https://www.youtube.com/watch?v=3I_vcrmE0a0",
    contractionType: "isotonic",
    icon: HandstandIcon
  },
  [EXERCISE_KEYS.HEIGHT_ASSISTED_HANDSTAND_PRESS]: {
    title: "Height Assisted Leg Lifts",
    description:
      "Begin the exercise with your feet elevated on a box or any other stable elevated surface. The higher your legs, the easier the movement. The additional height makes it easier for your body to get into a stacked position, necessary for moving into the Handstand. With your legs elevated, move your body forward slightly as done in the Press Walks. Once your legs are off the ground bring them up until your body is in a Handstand.",
    exerciseId: EXERCISE_KEYS.HEIGHT_ASSISTED_HANDSTAND_PRESS,
    resourceUrl: "https://www.youtube.com/watch?v=bwvfCR27czo",
    contractionType: "isotonic",
    icon: HandstandIcon
  },
  [EXERCISE_KEYS.HOLLOW_HOLD]: {
    title: "Hollow Hold",
    description: (
      <p>
        The <Link to="/exercises/hollow-body-hold">Hollow Body Hold</Link> works
        your core and helps target the lower abs, an often underworked section
        of the core. Use the Hollow Body Hold to build your core strength
        foundation. It's also a great exercise for improving posture. This is
        due to the emphasis on performing a posterior pelvic tilt when in the
        Hollow Body position.{" "}
        <Link to="/exercises/hollow-body-hold/">
          Learn how to perform the Hollow Body Hold here
        </Link>
        .
      </p>
    ),
    exerciseId: EXERCISE_KEYS.HOLLOW_HOLD,
    resourceUrl: "https://www.youtube.com/embed/uQPh7ZucTrI",
    contractionType: "isometric",
    icon: HollowHoldIcon
  },
  [EXERCISE_KEYS.HUMAN_FLAG]: {
    title: "Human Flag",
    description:
      "Get into your straddle flag position, and start to straighten out your legs. Press as hard as you can with your lower arm, and pull with your top arm. Make sure that your hips are level and your body is tight and engaged throughout",
    exerciseId: EXERCISE_KEYS.HUMAN_FLAG,
    resourceUrl: "",
    contractionType: "isometric",
    icon: HumanFlagIcon
  },
  [EXERCISE_KEYS.HYPER_EXTENSION]: {
    title: "Hyper Extension",
    description:
      "Using a Hyper Extension machine (or a Roman Chair), place your legs in safely one at time. The head of the machine should rest comfortably on your upper thighs/lower hips. In the resting position ensure that the muscles in your posterior chain are engaged, these include your glutes, hamstrings, and quads. Place your hands on your chest, engage your core and pull your torso upwards so that your body becomes a straight line. In a slow, controlled movement, bend at your hips while keeping your back straight and not arched. Come down as far as is comfortable and repeat the upwards movement.",
    exerciseId: EXERCISE_KEYS.HYPER_EXTENSION,
    resourceUrl: "https://www.youtube.com/embed/X5WPkTCo3AM?start=8",
    contractionType: "isotonic",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.INCLINE_PUSH_UP]: {
    title: "Incline Push Up",
    description:
      "Using a table, or any sturdy raised platform, place your hands shoulder-width apart and your feet firmly planted on the ground, close together. Torque your shoulders so the inside of your elbows are in front of you. Ensure your core is tight and slowly bring your body toward your hands, with your elbows bending close to your waist and not flaring outwards. Once at the bottom of the position, push the surface with your hands and come up into the starting position. ",
    exerciseId: EXERCISE_KEYS.INCLINE_PUSH_UP,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: PushUpIcon
  },
  [EXERCISE_KEYS.INCLINE_ROW]: {
    title: "Incline Row",
    description:
      "Perform the Vertical Row, but with the bar positioned lower and your feet positioned on the other side of the bar. The further your feet are from the back of the bar, the move difficult the exercise.",
    exerciseId: EXERCISE_KEYS.INCLINE_ROW,
    resourceUrl: "https://www.youtube.com/embed/sJe5xLbxaxg",
    contractionType: "isotonic",
    icon: RowIcon
  },
  [EXERCISE_KEYS.INVERTED_HANG]: {
    title: "Inverted Hang",
    description:
      "To perform this exercise, ensure that you have the grip strength to hold your bodyweight for 30+ seconds. Begin in a hanging position on the rings or bar. Tuck your knees to your chest and perform the beginning of a Skin The Cat. When you're upside down, extend your legs out, so your body is a straight vertical line with your head pointing down. Squeeze your butt, retract your shoulders and point your toes. To exit just tuck your knees and turn back to starting.",
    exerciseId: EXERCISE_KEYS.INVERTED_HANG,
    resourceUrl: "https://youtu.be/qKjdH5Lan5o",
    contractionType: "isometric",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.JEFFERSON_CURLS]: {
    title: "Jefferson Curls",
    description: (
      <>
        <p>
          The Jefferson Curl will have you using a little weight to create a
          loaded stretch on your hamstrings. Anything a few kilograms heavy will
          suffice.
        </p>
        <p>
          Don't move the hips back when performing this exercise. Instead tuck
          your chin to your chest and slowly roll your spine downwards. Keep
          your legs straight throughout by engaging your quads.
        </p>
        <p>
          To move to the next exercise, get your palms to the floor without
          weight.
        </p>
      </>
    ),
    exerciseId: "jefferson-curls",
    resourceUrl: "https://www.youtube.com/watch?v=y_APeWo643w",
    contractionType: "isotonic",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.KNEES_AB_WHEEL]: {
    title: "Knees Ab Wheel",
    description:
      "Begin in the keeling position, with a posterior pelvic tilt, so your back doesn't dip towards the ground. Engage your core, and maintain this position throughout the exercise. Control the movement of the ab wheel until your chest is an inch or two away from the floor. Contract your core to pull your body back into the starting position.",
    exerciseId: EXERCISE_KEYS.KNEES_AB_WHEEL,
    resourceUrl: "https://www.youtube.com/embed/rqiTPdK1c_I?start=47",
    contractionType: "isotonic",
    icon: PlankIcon
  },
  [EXERCISE_KEYS.KNEE_COPENHAGEN_PLANK]: {
    title: "Kneeling Copenhagen Plank",
    description:
      "Get into a side plank position with your forearm resting on the ground. Allow the knee, calf and foot of your upper leg to rest on a soft surface and keep the lower leg in front of you. Remain balanced in this position with your core braced. If the movement is too hard, your lower leg can rest on the ground and provide assistance. Repeat for both sides.",
    exerciseId: EXERCISE_KEYS.KNEE_COPENHAGEN_PLANK,
    resourceUrl: "https://youtu.be/9jZrRO4ppeA",
    contractionType: "isometric",
    icon: PlankIcon
  },
  [EXERCISE_KEYS.KNEES_TO_CHEST]: {
    title: "Knees To Chest",
    description:
      "Hang from the bar or rings, and perform the hanging knee raise. This time when you bring your legs to parallel, squeeze your core and bring your legs as close to your chest as possible. The further your extend your legs out, the more challenging the exercise.",
    exerciseId: EXERCISE_KEYS.KNEES_TO_CHEST,
    resourceUrl: "https://www.youtube.com/watch?v=PHCd3B-ZbCU",
    contractionType: "isotonic",
    icon: ToesToBarIcon
  },
  [EXERCISE_KEYS.LSIT]: {
    title: "L-Sit",
    description: (
      <p>
        The L-Sit is a both a vertical pushing movement and a vigorous core
        exercise. By working on your L-Sit, you'll be strengthening your core,
        hip flexors, and quads. In addition, you'll also work your chest and
        triceps. By improving your core and hip flexor strength, the L-Sit will
        help improve posture and make your body more resilient.{" "}
        <Link to="/exercises/l-sit">Learn how to perform the L-Sit</Link>.
      </p>
    ),
    exerciseId: EXERCISE_KEYS.LSIT,
    resourceUrl: "https://youtu.be/BbAkWxDZKIM?t=300",
    contractionType: "isometric",
    icon: LSitIcon
  },
  [EXERCISE_KEYS.LSIT_DIP]: {
    title: "L-Sit Dip",
    description:
      "Begin the exercise in a bar support hold and raise your legs into an L-sit position. Keep your chest up right while in this position and perform a dip. This variation emphasises the triceps and removes body's the reliance on the chest.",
    exerciseId: EXERCISE_KEYS.LSIT_DIP,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: LSitIcon
  },
  [EXERCISE_KEYS.LSIT_PULL_UP]: {
    title: "L-Sit Pull Up",
    description:
      "Assume the starting position in much the same as the pull up. While hanging from the bar, perform an l-sit position, keep your legs straight and, by squeezing your core and quadriceps, lift your legs until they're parallel with the ground. Perform the full pull-up movement while maintaining this L position. ",
    exerciseId: EXERCISE_KEYS.LSIT_PULL_UP,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.MANNA]: {
    title: "Manna",
    description:
      "The Manna is one of the most challenging core exercises. It requires incredible core compression strength, tricep strength and shoulder flexibility. This position comes as a result of pushing your hips further upward until both your torso and legs are parallel with the ground.",
    exerciseId: EXERCISE_KEYS.MANNA,
    resourceUrl: "https://youtu.be/L7reIo5f6uo",
    contractionType: "isometric",
    icon: LSitIcon
  },
  [EXERCISE_KEYS.MOVING_COPENHAGEN_PLANK]: {
    title: "Moving Copenhagen Plank",
    description:
      "Once in the Copenhagen plank, move the foot of the bent leg from the back to the front and back again. Repeat this for reps.",
    exerciseId: EXERCISE_KEYS.MOVING_COPENHAGEN_PLANK,
    resourceUrl: "https://youtu.be/1NNqUQvMYGc",
    contractionType: "isometric",
    icon: PlankIcon
  },
  [EXERCISE_KEYS.MUSCLE_UP]: {
    title: "Muscle Up",
    description: (
      <p>
        The gold standard pulling/pushing skill. Pull yourself up and around the
        bar, and finish off with a dip. Performing the Muscle Up demonstrates
        tremendous pulling and pushing strength as well as upper body power.{" "}
        <Link to="/exercises/muscle-up/">
          Learn how to perform the Muscle Up
        </Link>
        .
      </p>
    ),
    exerciseId: EXERCISE_KEYS.MUSCLE_UP,
    resourceUrl: "https://www.youtube.com/embed/aUIrHBxPcPU",
    contractionType: "isotonic",
    icon: MuscleUpIcon
  },
  [EXERCISE_KEYS.NEG_BAR_DIP]: {
    title: "Parallel Bar Dip (Negative)",
    description:
      "Begin the exercise in the bar support hold and stay in a hollow body position. As you begin the descent, lean forward slightly and bend your arms while preventing your elbows form flaring outwards, engage your triceps and chest, controlling the movement with these muscles. Keep chest proud and keep the head in a neutral position, end the movement as the angle between your arms comes below 90°. Allow the negative movement to last 4 seconds.",
    exerciseId: EXERCISE_KEYS.NEG_BAR_DIP,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: ParallelBarDipIcon
  },
  [EXERCISE_KEYS.NEG_MUSCLE_UP]: {
    title: "Muscle Up (Negative)",
    description: (
      <p>
        Using a box, get into the top position of a straight bar dip, with arms
        straight. Perform a dip until your chest comes in close contact with the
        bar. During the transition, lean your torso back slightly and keep the
        elbows tight with the body (don't let them flare out). As you lean back,
        control the movement as your body moves downwards below the bar. Then
        treat the rest like a negative Pull Up.
      </p>
    ),
    exerciseId: EXERCISE_KEYS.NEG_MUSCLE_UP,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: MuscleUpIcon
  },
  [EXERCISE_KEYS.NEG_NORDIC_CURL]: {
    title: "Nordic Curl (Negative)",
    description:
      "Perform only the eccentric portion of the Nordic Curl and use assistance to get back into the starting position.",
    exerciseId: EXERCISE_KEYS.NEG_NORDIC_CURL,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: NordicCurlIcon
  },
  [EXERCISE_KEYS.NEG_OA_PULL_UP]: {
    title: "One-Armed Pull Up (Negative)",
    description:
      "Perform a regular Chin Up to bring yourself to the top position. With control, shift your weight over to one arm, and then release your grip on the bar with the other arm. Slowly make your way down to a hang position. Repeat for the other arm.",
    exerciseId: EXERCISE_KEYS.NEG_OA_PULL_UP,
    resourceUrl: "https://youtu.be/HLf1ccm2u2E?t=247",
    contractionType: "isotonic",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.NEG_OA_PUSH_UP]: {
    title: "One-Armed Push Up (Negative)",
    description:
      "Begin the exercise in the top position of the Push Up. Straddle your legs and put your body's weight on your left arm and move your right arm to your lower back. While keeping your elbow close to your waist, lower your body down to the ground. Do this in a controlled way using the strength in your chest and triceps.",
    exerciseId: EXERCISE_KEYS.NEG_OA_PUSH_UP,
    resourceUrl: "https://youtu.be/kuM-IvATYms?t=20",
    contractionType: "isotonic",
    icon: PushUpIcon
  },
  [EXERCISE_KEYS.NEG_PULL_UP]: {
    title: "Pull Up (Negative)",
    description: (
      <p>
        This is the same exercise as the{" "}
        <Link to="/exercises/pull-up/">Pull Up</Link> but performing just the
        negative portion of the exercise. Don't pull your self up to the bar,
        but begin the exercise at the top position. Engage your lats and step
        off the elevated surface. Control the movement down over a period of 4
        seconds until your arms are full extended.
      </p>
    ),
    exerciseId: EXERCISE_KEYS.NEG_PULL_UP,
    resourceUrl: "https://www.youtube.com/embed/gbPURTSxQLY",
    contractionType: "isotonic",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.NEG_RING_DIP]: {
    title: "Ring Dip (Negative)",
    description:
      "Begin the exercise in the ring support hold. TO begin the negative, slowly lean forward and start bending your elbows. Keep your core engage and feet together, and remain in this hollow body position. When your forearms and biceps have moved beyond a perpendicular position, end the movement. Repeat with a tempo of 4 seconds for the negative movement.",
    exerciseId: EXERCISE_KEYS.NEG_RING_DIP,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: ParallelBarDipIcon
  },
  [EXERCISE_KEYS.NEG_TUCK_DRAGON_FLAG]: {
    title: "Tucked Dragon Flag (Negative)",
    description: (
      <p>
        The Negative Tuck Dragon Flag is an early regression for one of the most
        ferocious ab-burners, the{" "}
        <Link to="/exercises/dragon-flag">Dragon Flag</Link>. Tuck your knees
        close to your chest, push your weight up into your shoulders and slowly
        bring your body back to the ground without breaking your hollow body.
        <Link to="/exercises/dragon-flag/">
          Learn how to perform the Dragon Flag and its regressions
        </Link>
        .
      </p>
    ),
    exerciseId: EXERCISE_KEYS.NEG_TUCK_DRAGON_FLAG,
    resourceUrl: "https://youtu.be/DJnX__TFB9o?t=53",
    contractionType: "isotonic",
    icon: DragonFlagIcon
  },
  [EXERCISE_KEYS.NEG_WALL_HEADSTAND_PUSH_UP]: {
    title: "Wall Headstand Push Up",
    description: (
      <p>
        Walk up the wall to begin the exercise. Make sure your body is facing
        the wall and your hands are 30cm away from it. Press your legs together
        and ensure you've engaged your core. Slowly bend your arms, but keep
        your elbows close to your waist. Bring your head to touch the floor
        about 30cm in front of your hands. Perform this similar to a{" "}
        <Link to="/exercises/pike-push-up">Pike Push Up</Link>. End the movement
        when your head reaches the floor.
      </p>
    ),
    exerciseId: EXERCISE_KEYS.NEG_WALL_HEADSTAND_PUSH_UP,
    resourceUrl: "https://youtu.be/dupP2OgUJIQ?t=35",
    contractionType: "isotonic",
    icon: WallHandstandIcon
  },
  [EXERCISE_KEYS.NINETY_DEG_VSIT]: {
    title: "90° V-Sit",
    description:
      "Gradually increase the range that your legs come off the ground. As the angle increases, so will the difficulty of the exercise as it will require greater hamstring flexibility and pike compression. The hips should start to shift as the legs are pushed further.",
    exerciseId: EXERCISE_KEYS.NINETY_DEG_VSIT,
    resourceUrl: "https://youtu.be/u4GFwp7HVAM?t=419",
    contractionType: "isometric",
    icon: LSitIcon
  },
  [EXERCISE_KEYS.NORDIC_CURL]: {
    title: "Nordic Curl",
    description:
      "A leg exercises that targets the hamstring. In a kneeling position, your feet should be locked in place. Keeping core tight and using the strength of your hamstrings, slowly bring your torso to the floor and back up again.",
    exerciseId: EXERCISE_KEYS.NORDIC_CURL,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: NordicCurlIcon
  },
  [EXERCISE_KEYS.OA_PULL_UP]: {
    title: "One-Armed Pull Up",
    description:
      "Start by hanging on the bar with one arm. Begin by perform a scapular pull. Once your scapular is fully retracted and depressed, bend your arm to pull yourself up to the top of the bar. Perform the negative to come back to starting in a controlled manner. swap over to your other arm.",
    exerciseId: EXERCISE_KEYS.OA_PULL_UP,
    resourceUrl: "https://www.youtube.com/watch?v=vdjWgw98EeI",
    contractionType: "isotonic",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.OA_PUSH_UP]: {
    title: "One-Armed Push Up",
    description:
      "Begin the exercise in the same position as the Negative One-Armed Push Up. Engage your abs, legs and glutes.  Bend your arm and keep your elbow close to your waist. Once at the bottom position, push down with your chest and tricep to bring yourself up to starting. Try to keep your shoulders level as you're pushing your body upwards.",
    exerciseId: EXERCISE_KEYS.OA_PUSH_UP,
    resourceUrl: "https://youtu.be/ZPSdIZMRboQ?t=23",
    contractionType: "isotonic",
    icon: PushUpIcon
  },
  [EXERCISE_KEYS.OA_SCAPULAR_PULL]: {
    title: "One-Armed Scapular Pull",
    description:
      "Begin the exercise hanging from a bar with one arm. Ensure you've engaged your entire body, squeeze your legs together and engage your core. With the arm you're using to hang, retract your scapular (as if you want it to touch your other shoulder blade). Then depress your scapular (try to pull it down toward this floor.) Hold this position for a second, then slowly move back to starting. Repeat for a number of reps and then swap arms.",
    exerciseId: EXERCISE_KEYS.OA_SCAPULAR_PULL,
    resourceUrl: "https://youtu.be/hGSEODUF4ys?t=554",
    contractionType: "isometric",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.ONE_ARMED_HANDSTAND]: {
    title: "One Armed Handstand",
    description:
      "Perform the exercise exactly as the Straddled One Arm Handstand but with your legs together, so that your body is one long vertical line.",
    exerciseId: EXERCISE_KEYS.ONE_ARMED_HANDSTAND,
    resourceUrl: "",
    contractionType: "isometric",
    icon: HandstandIcon
  },
  [EXERCISE_KEYS.ONE_ARMED_PLANK]: {
    title: "One Armed Plank",
    description:
      "Begin the exercise in the plank position. Brace your core and engage your glutes. In a controlled manner, shift your weight on to your right arm, and raise your left arm so it's pointing directly ahead of you, the rest of your body should remain in the plank position. Hold for up to a minute, slowly lower your left arm. Once you're back in your starting position, switch arms.",
    exerciseId: EXERCISE_KEYS.ONE_ARMED_PLANK,
    resourceUrl: "",
    contractionType: "isometric",
    icon: PlankIcon
  },
  [EXERCISE_KEYS.ONE_ARMED_ROW]: {
    title: "One Armed Row",
    description:
      "The same movement as the Straddle One Arm Row, but with your feet positioned together, and with your body starting in the row position. Pull the ring toward your chest, keeping core, legs and butt engaged throughout. ",
    exerciseId: EXERCISE_KEYS.ONE_ARMED_ROW,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: RowIcon
  },
  [EXERCISE_KEYS.ONE_LEGGED_DEADLIFT]: {
    title: "One Legged Deadlift",
    description:
      "A leg exercise that targets the hamstring. Stand on one leg with the knee slightly. Bend at your hip and lower your body, and raise your non-standing leg until you're parallel with the ground. Return back to standing and repeat with the other leg.",
    exerciseId: EXERCISE_KEYS.ONE_LEGGED_DEADLIFT,
    resourceUrl: "https://youtu.be/5_kxfjJhB4I",
    contractionType: "isotonic",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.ONE_LEGGED_NORDIC_CURL]: {
    title: "One Legged Nordic Curl",
    description:
      "A regular Nordic Curl, but with a single leg controlling the movement.",
    exerciseId: EXERCISE_KEYS.ONE_LEGGED_NORDIC_CURL,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: NordicCurlIcon
  },
  [EXERCISE_KEYS.ONE_TWENTY_VSIT]: {
    title: "120° V-Sit",
    description:
      "As you increase the angle of your legs in relation to the ground, start pushing your hips further up into the air, have gone beyond the 90 degree point.",
    exerciseId: EXERCISE_KEYS.ONE_TWENTY_VSIT,
    resourceUrl: "",
    contractionType: "isometric",
    icon: LSitIcon
  },
  [EXERCISE_KEYS.PARALLEL_SQUAT]: {
    title: "Parallel Squat",
    description: (
      <p>
        The Parallel Squat is a regression of the Full Squat. The only
        difference is this exercises only requires you to bring your hips to
        parallel. This is a great exercise to help slowly the improve the
        mobility in your ankles and hips.{" "}
        <Link to="/exercises/bodyweight-squat">
          Learn how to perform the Squat here
        </Link>
        .
      </p>
    ),
    exerciseId: EXERCISE_KEYS.PARALLEL_SQUAT,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: ParallelSquatIcon
  },
  [EXERCISE_KEYS.PIKE_PUSH_UP]: {
    title: "Pike Push Up",
    description: (
      <p>
        The Pike Push Up, or Pike Press, is a modified version of the standard
        Push Up. The key difference between the Pike Push Up the standard Push
        Up is the orientation of the torso in relation to the arms. With the
        regular Push Up, the body is straight and near parallel with the ground.
        The legs are much closer to the arms in the Pike Push Up, which elevates
        the hips until they're almost overhead.{" "}
        <Link to="/exercises/pike-push-up/">
          Learn how to perform the Pike Push Up
        </Link>
        .
      </p>
    ),
    exerciseId: EXERCISE_KEYS.PIKE_PUSH_UP,
    resourceUrl: "https://youtu.be/VnQU_lLBFW0?t=35",
    contractionType: "isotonic",
    icon: PikePushUpIcon
  },
  [EXERCISE_KEYS.PIKE_SKIN_THE_CAT]: {
    title: "Pike Skin The Cat",
    description:
      "Perform the same motion as the Tuck Skin the Cat, but with your legs extended and your core engaged to move through the motion in a pike position.",
    exerciseId: EXERCISE_KEYS.PIKE_SKIN_THE_CAT,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: PikeSkinTheCatIcon
  },
  [EXERCISE_KEYS.PISTOL_SQUAT]: {
    title: "Pistol Squat",
    description:
      "Stand shoulder-width apart. Stand on your right leg. Tense your hip flexors and raise your leg left off the ground in front of you. As you move down into the squat, keep the left leg off the ground. Hold the position at the bottom of the squat and apply pressure to the right foot to bring your body into a standing position. Repeat with your other leg.",
    exerciseId: EXERCISE_KEYS.PISTOL_SQUAT,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: PistolSquatIcon
  },
  [EXERCISE_KEYS.PLANCHE]: {
    title: "Planche",
    description:
      "The Planche is one of the most challenging calisthenics exercises and requires incredible straight-arm strength. Begin by performing the Straddle Plank, once in position, bring your legs together, and ensure every part of your body is engaged.",
    exerciseId: EXERCISE_KEYS.PLANCHE,
    resourceUrl: "",
    contractionType: "isometric",
    icon: PlancheIcon
  },
  [EXERCISE_KEYS.PLANCHE_LEAN]: {
    title: "Planche Lean",
    description:
      "Begin in the Plank position and turn your hands outwards as much as is comfortable. Maintain a rigid plank pose throughout and begin to lean your body forward. Aim for your hands to be directly below your waist and hold this static position for as long as possible, return to Plank to finish. Use a measure to keep track your range consistently.",
    exerciseId: EXERCISE_KEYS.PLANCHE_LEAN,
    resourceUrl: "https://youtu.be/6lgNlJZpE1Y?t=19",
    contractionType: "isometric",
    icon: PlankIcon
  },
  [EXERCISE_KEYS.PLANCHE_PUSH_UP]: {
    title: "Planche Push Up",
    description:
      "A stunning exercise that requires years of dedication. The Planche Push-Up is a true display of both straight-arm, bent-arm, and pushing strength. On a pair of parallettes, enter your Planche. When at the top of the position, perform the Push Up as described in the Tuck Planche Push Up.",
    exerciseId: EXERCISE_KEYS.PLANCHE_PUSH_UP,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: PlancheIcon
  },
  [EXERCISE_KEYS.PLANK]: {
    title: "Plank",
    description: (
      <p>
        The <Link to="/exercises/plank/">Plank</Link> is a fundamental
        bodyweight exercise, and lays the foundation for more challenging core
        exercises. Beginners may benefit the most from performing the plank
        exercise with perfect form. This is because the plank primarily targets
        various core muscles, including the abs and the spinal erectors.{" "}
        <Link to="/exercises/plank/">Learn how to perform the Plank here</Link>.
      </p>
    ),
    exerciseId: EXERCISE_KEYS.PLANK,
    resourceUrl: "https://www.youtube.com/embed/ASdvN_XEl_c?start=91",
    contractionType: "isometric",
    icon: PlankIcon
  },
  [EXERCISE_KEYS.PRESS_WALKS]: {
    title: "Press Walks",
    description:
      "Bring your palms to the floor a few inches in front of your feet. Push your down into the ground and protract your shoulders. Try and keep your ears by your shoulders. Lean forward slightly so your shoulders come slightly over your wrists while moving on to your toes. Lift your feet off the ground by a couple of inches and land them by your hands.",
    exerciseId: EXERCISE_KEYS.PRESS_WALKS,
    resourceUrl: "https://www.youtube.com/watch?v=NQ7e2NxEJuM",
    contractionType: "isotonic",
    icon: PikePushUpIcon
  },
  [EXERCISE_KEYS.PSEUDO_PLANCHE_PUSH_UP]: {
    title: "Pseudo Planche Push Up",
    description:
      "Start in a Plank position with hands turned outwards as much as comfortable. Lean your body forward so that your hands are directly underneath your waist. Keep your back in a straight line and perform a push up. Focus on keeping your body leaning the same amount throughout the exercise. ",
    exerciseId: EXERCISE_KEYS.PSEUDO_PLANCHE_PUSH_UP,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: PlankIcon
  },
  [EXERCISE_KEYS.PULLOVER]: {
    title: "Pullover",
    description:
      "Begin the exercise in the dead hang position with a pronated grip on the bar. Perform a full leg raise and move into a rotated L-sit, with your legs pointing straight into the air and your body parallel with the ground. Lift up with your lower back and hips until your belly button reaches the bar, then lean your feet over the other side of the bar until the weight distribution causes your body to rotate. Slowly bring your down from the bar. ",
    exerciseId: EXERCISE_KEYS.PULLOVER,
    resourceUrl: "https://www.youtube.com/embed/xA7TGSSEvaw?start=117",
    contractionType: "isotonic",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.PULL_UP]: {
    title: "Pull Up",
    description: (
      <p>
        The Pull Up is a fundamental pulling movement. The primary muscles used
        during this exercise is are the latissimus dorsi and the biceps brachii,
        but dozens of other muscles are also worked. The aim of the movement is
        to move the body from a straight-arm hanging position, to one where the
        elbows are flexed and the chin is above the bar.{" "}
        <Link to="/exercises/pull-up/">Learn how to perform the Pull Up</Link>.
      </p>
    ),
    exerciseId: EXERCISE_KEYS.PULL_UP,
    resourceUrl: "https://www.youtube.com/embed/eGo4IYlbE5g",
    contractionType: "isotonic",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.PUSH_UP]: {
    title: "Push Up",
    description: (
      <p>
        The Push Up (also known as the Press Up) is a fundamental bodyweight
        exercise. The Push Up is regarded as one of the best chest and tricep
        exercises. While the Push Up is used as the basis for more advanced
        calisthenics skills, it's often seen as a challenging movement for
        beginners. One of the biggest challenges when performing a Push Up is
        getting the form perfect. As with any exercise, improper form can lead
        to muscle imbalances and injury.{" "}
        <Link to="/exercises/push-up/">Learn how to perform the Push Up</Link>.
      </p>
    ),
    exerciseId: EXERCISE_KEYS.PUSH_UP,
    resourceUrl: "https://www.youtube.com/embed/IODxDxX7oi4",
    contractionType: "isotonic",
    icon: PushUpIcon
  },
  [EXERCISE_KEYS.REVERSE_PLANK]: {
    title: "Reverse Plank",
    description:
      "Sit up on the floor with your legs stretched straight out ahead of you. Lean back slightly and plan your hands on the floor directly under your elbows. Lift your hips up and engage your glutes. Hold this position with your core engaged and ensure that your body is a straight line, from toes to head.",
    exerciseId: EXERCISE_KEYS.REVERSE_PLANK,
    resourceUrl: "https://youtu.be/UB4AG8EyHmw",
    contractionType: "isometric",
    icon: PlankIcon
  },
  [EXERCISE_KEYS.REV_HYPER_EXTENSION]: {
    title: "Reverse Hyper Extension",
    description:
      "Find a stable surface that can support your bodyweight. Lie your torso on top of the surface, and let everything below your waist hang off. If you're on a plyo-box, hold the outside of the box with your arms for support. Contract your glutes, and while maintaining straight legs, lift them until your body is fully horizontal. You want to use your glutes to bring your legs up and not your lower back, so ensure you're not extending your lower back. Slowly lower your legs, back to the starting position.",
    exerciseId: EXERCISE_KEYS.REV_HYPER_EXTENSION,
    resourceUrl: "https://www.youtube.com/embed/3d9_W--eUcI?start=73",
    contractionType: "isotonic",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.RING_ARCHER_PULL_UP]: {
    title: "Ring Archer Pull Up",
    description:
      "Begin the exercise in the hanging position. Using your left arm , perform a Pull Up, with your right arm remaining straight and moving laterally so it's parallel to the floor. When you're in the top position, you'll should be in a similar position to that of the Typewriter Pull Up. One arm should be bent close to your waist, while the other is straight out at your side. Slowly come back down to starting and swap arms.",
    exerciseId: EXERCISE_KEYS.RING_ARCHER_PULL_UP,
    resourceUrl: "https://youtu.be/sdwuUbqk6UI?t=538",
    contractionType: "isotonic",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.RING_ARCHER_PUSH_UP]: {
    title: "Ring Archer Push Up",
    description:
      "Begin in the top position for the Ring Push Up. Start by lowering your body by bending your left arm and keeping you elbow close to your body. At the same time, keep your right arm straight and reach it out to your side. Once at the bottom position, push down with your left arm to bring yourself up to starting. Then repeat on the other side. Think of this exercise as if one arm is performing a Push Up, while the other is performing a Flye.",
    exerciseId: EXERCISE_KEYS.RING_ARCHER_PUSH_UP,
    resourceUrl: "https://youtu.be/szi1uKCRgh0?t=265",
    contractionType: "isotonic",
    icon: PlankIcon
  },
  [EXERCISE_KEYS.RING_ARCHER_ROW]: {
    title: "Ring Archer Row",
    description:
      "With rings, get in the incline row position with palms facing the floor. Pull your body toward you with your left arm, keeping your elbows tight to your waist. As your left hand approaches your chest, turn your wrist counter-clockwise by 90°.  Keep your right arm straight throughout the exercise, using it to performing a horizontal extension. As your right arms becomes perpendicular with your left arm, turn your wrists 90° clockwise.",
    exerciseId: EXERCISE_KEYS.RING_ARCHER_ROW,
    resourceUrl: "https://www.youtube.com/embed/d-Arud18o30",
    contractionType: "isotonic",
    icon: RowIcon
  },
  [EXERCISE_KEYS.RING_DIP]: {
    title: "Ring Dip",
    description:
      "Begin the exercise in the ring support hold, and perform the eccentric portion of the movement. Once you've reached the bottom position press down with your arms to bring your body back to the starting position. Don't let the rings turn inwards when moving through the concentric portion of the movement.",
    exerciseId: EXERCISE_KEYS.RING_DIP,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: ParallelBarDipIcon
  },
  [EXERCISE_KEYS.RING_FLYES]: {
    title: "Ring Flyes",
    description:
      "Assume a Push-up position on the rings. Throughout the whole movement, keep your arms locked out. Slowly spread your arms outward, bringing your body close to the ground. Control the movement down, and stop when your face is level with the rings. Squeeze your chest and arms back together to bring yourself up to the starting position. ",
    exerciseId: EXERCISE_KEYS.RING_FLYES,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.RING_HANDSTAND]: {
    title: "Ring Handstand",
    description:
      "Similar to the Ring Shoulder Stand but with arms fully straight. As the center of gravity is much higher, balancing on the rings becomes much more challenging.",
    exerciseId: EXERCISE_KEYS.RING_HANDSTAND,
    resourceUrl: "",
    contractionType: "isometric",
    icon: HandstandIcon
  },
  [EXERCISE_KEYS.RING_OA_PUSH_UP]: {
    title: "Ring One-Armed Push Up",
    description:
      "The instability of the ring makes this variation a lot more challenging. When performing the exercise, perform it like the One-Armed Push Up, with the key difference being to hold a ring with the arm that's handling the resistance.",
    exerciseId: EXERCISE_KEYS.RING_OA_PUSH_UP,
    resourceUrl: "https://www.youtube.com/watch?v=hWd8PxS8dck",
    contractionType: "isotonic",
    icon: PushUpIcon
  },
  [EXERCISE_KEYS.RING_ROWS]: {
    title: "Ring Row",
    description:
      "The Ring Row is an alternative to the bodyweight row. Get in the same position as the bodyweight row's starting point and grab on the rings. When pulling your chest toward the rings, fight to keep the body in line and don't let your hips sink either side.",
    exerciseId: EXERCISE_KEYS.RING_ROWS,
    resourceUrl: "https://youtu.be/PGcTxvw6-lo?t=32",
    contractionType: "isotonic",
    icon: RowIcon
  },
  [EXERCISE_KEYS.RING_SHOULDER_STAND]: {
    title: "Ring Shoulder Stand",
    description:
      "The Ring Shoulder Stand is an exercise where your body is fully inverted but with your arms bent. Begin in a tucked position, and slowly bring your legs upward until your body is fully straight.",
    exerciseId: EXERCISE_KEYS.RING_SHOULDER_STAND,
    resourceUrl: "https://youtu.be/u-wi3J2uazg",
    contractionType: "isotonic",
    icon: HandstandIcon
  },
  [EXERCISE_KEYS.RING_SUPPORT_HOLD]: {
    title: "Ring Support Hold",
    description:
      "With the rings set at a height just above your waist, jump up into the support position. The form cues are the same as with the bar support hold. Keep legs together, tighten the glutes, and press your shoulders down. Be conscious to not let your hands turn inwards, the arms should be locked out and your forearms pointed forward. Hold this position, and when finished slowly bend your arms to bring your feet to the ground.",
    exerciseId: EXERCISE_KEYS.RING_SUPPORT_HOLD,
    resourceUrl: "",
    contractionType: "isometric",
    icon: BarSupportHoldIcon
  },
  [EXERCISE_KEYS.ROCKY_PUSH_UP]: {
    title: "Rocky Push Up",
    description: "The only true reason to train the One-Armed Push Up",
    exerciseId: EXERCISE_KEYS.ROCKY_PUSH_UP,
    resourceUrl: "https://youtu.be/DP3MFBzMH2o?t=50",
    contractionType: "isotonic",
    icon: PushUpIcon
  },
  [EXERCISE_KEYS.ROMANIAN_DEADLIFT]: {
    title: "Romanian Deadlift",
    description:
      "Begin by standing with your feet shoulder width apart, pointing directly in front of you. Put your hands on your hip. Engage your core and begin the exercise by pusing your butt back and leaning your body forword. Don't let your knees bend. You should feel a stretch in your hamstrings. Lower your body until it's parallel with the ground. Reverse the movement to come back to starting.",
    exerciseId: EXERCISE_KEYS.ROMANIAN_DEADLIFT,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.ROW]: {
    title: "Row",
    description: (
      <p>
        The Inverted Row, also known as the Supine Row or Bodyweight Row, is a
        key milestone back exercise. Achieving the Inverted Row ensures that
        your back, grip, and midline strength have a strong foundation. The
        Inverted Row is a great precursor to the Pull Up, as well as the Front
        Lever, and Front Lever Rows.{" "}
        <Link to="/exercises/inverted-row/">
          Learn how to perform the Inverted Row
        </Link>
        .
      </p>
    ),
    exerciseId: EXERCISE_KEYS.ROW,
    resourceUrl: "https://www.youtube.com/embed/hXTc1mDnZCw?start=11",
    contractionType: "isotonic",
    icon: RowIcon
  },
  [EXERCISE_KEYS.RTO_ARCHER_PUSH_UP]: {
    title: "Archer Push Up (RTO)",
    description:
      "Performed similarly to the regular Archer Push-up, but with the use of rings. Begin the movement by shifting your weight onto your left arm, but keeping your body straight and balanced. Perform a push-up and maintain a straight right arm. Bring yourself back to the starting starting position, turning the rings outwards once both arms are straight.",
    exerciseId: EXERCISE_KEYS.RTO_ARCHER_PUSH_UP,
    resourceUrl: "https://www.youtube.com/watch?v=6Qi89rsXJSU",
    contractionType: "isotonic",
    icon: PushUpIcon
  },
  [EXERCISE_KEYS.RTO_MALTESE_PUSH_UPS]: {
    title: "Maltese Push Up (RTO)",
    description:
      "Begin the movement in a Push-up position on the rings. Ensure that the rings are just a couple of inches off the ground. Engage your core and keep the elbows locked to your ribcage though out. Lower your body in a military style push up until your arms are at a 90° angle. As your move further down, straighten your arms behind you until your chest touches the ground. To reverse the movement, curl with your biceps until your arms are at a 90° angle, then push through the chest until arms are straight. End the movement by turning out the rings.",
    exerciseId: EXERCISE_KEYS.RTO_MALTESE_PUSH_UPS,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: PushUpIcon
  },
  [EXERCISE_KEYS.RTO_PUSH_UP]: {
    title: "Push Up (RTO)",
    description:
      "On a pair of low hanging gymnastic rings, assume standard Push-up form. Keep core engaged and legs tight. Don't let the rings turn inwards during the movement. Slowly descend until your shoulders touch the rings. Push up while maintaining a straight line with your body. Once your elbows have locked out at the top of the position, turn the rings outwards and maintain that isometric hold for a moment. Revert to starting position and repeat. ",
    exerciseId: EXERCISE_KEYS.RTO_PUSH_UP,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: PushUpIcon
  },
  [EXERCISE_KEYS.RTO_RING_DIP]: {
    title: "Ring Dip (RTO)",
    description:
      "Execute the Ring Dip as above, but at the top position, when reaching the support hold, turn the rings out until your palms are facing outwards. Hold for a second, and revert your hands.",
    exerciseId: EXERCISE_KEYS.RTO_RING_DIP,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: ParallelBarDipIcon
  },
  [EXERCISE_KEYS.RUSSIAN_DIP]: {
    title: "Russian Dip",
    description:
      "Begin the exercise at the bottom portion of the bar dip. Rest your elbows on the bar so your forearms are flat on the bar. Keep core and legs tight, quickly transfer the weight on to your arms and lift up the elbows, which will bring you to the bottom of the bar dip. Perform the rest of the exercise, the concentric portion of the bar dip. Lower your body and slowly bring your forearms and elbows back to the start position. ",
    exerciseId: EXERCISE_KEYS.RUSSIAN_DIP,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: ParallelBarDipIcon
  },
  [EXERCISE_KEYS.SCAPULAR_PULL]: {
    title: "Scapular Pull",
    description:
      "Hang from a pull up bar with a pronated grip with hands slightly wider than shoulder width. Keep arms straight the whole movement. From the dead hang position, pull downwards with your shoulders so that your ears come away from your shoulders. Hold the position, and then slowly release. Remember to not pull with your biceps. Keep your core engaged, and squeeze with your legs and remain in a hollow body position. ",
    exerciseId: EXERCISE_KEYS.SCAPULAR_PULL,
    resourceUrl: "https://www.youtube.com/embed/icD6a_JcCbQ",
    contractionType: "isometric",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.SEATED_PIKE_LEG_LIFT]: {
    title: "Seated Pike Leg Lift",
    description:
      "Sit on the ground with your legs stretched outward and your feet pointed. Keeping your back straight, put your hands flat next to your knees. Use your core to pull your legs off the ground and toward your chest. Lift your legs up and down to work your core compression",
    exerciseId: EXERCISE_KEYS.SEATED_PIKE_LEG_LIFT,
    resourceUrl: "https://youtu.be/5NU3HsSvP3Y",
    contractionType: "isotonic",
    icon: LSitIcon
  },
  [EXERCISE_KEYS.SHOULDER_BRIDGE]: {
    title: "Shoulder Bridge",
    description:
      "Lie on your back and plant your feet near your butt so your knees are pointed toward the ceiling. Push down with your legs until your hips are in the air, and your body's weight is carried on your shoulders and feet.",
    exerciseId: EXERCISE_KEYS.SHOULDER_BRIDGE,
    resourceUrl: "https://youtu.be/iSGYRP6bXW8",
    contractionType: "isometric",
    icon: WheelIcon
  },
  [EXERCISE_KEYS.SHRIMP_SQUAT]: {
    title: "Shrimp Squat",
    description:
      "Hold your right foot with your right arm, and pull your heel as close to your butt as possible. Slowly squat down with your chest proud until your right knee touches the floor. Remain balanced, and slowly rise back to standing.  ",
    exerciseId: EXERCISE_KEYS.SHRIMP_SQUAT,
    resourceUrl: "https://www.youtube.com/watch?v=ZfIGp2BlB5Q",
    contractionType: "isotonic",
    icon: SquatIcon
  },
  [EXERCISE_KEYS.SIDE_PLANK]: {
    title: "Side Plank",
    description:
      "Move into a plank postion. Shift your weight onto your right arm and rotate your body 90 degrees anti-clockwise. Your feet should be stacked on top of each other and the right side of your body should be facing the floor. Ensure core and glutes are engaged. Repeat for both sides of your body.",
    exerciseId: EXERCISE_KEYS.SIDE_PLANK,
    resourceUrl: "https://youtu.be/BBUEzjlwM1o?t=10",
    contractionType: "isometric",
    icon: PlankIcon
  },
  [EXERCISE_KEYS.SPLIT_SQUAT]: {
    title: "Split Squat",
    description:
      "Start the exercise with your right leg far behind the left leg, akin to the standing position of a lunge. Keep most of your weight on your front food until both legs are at a 90degree angle. Push up with your front leg until your in your starting ~~position~~. Repeat from reps.",
    exerciseId: EXERCISE_KEYS.SPLIT_SQUAT,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: SquatIcon
  },
  [EXERCISE_KEYS.STRADDLE_BACK_LEVER]: {
    title: "Straddle Back Lever",
    description:
      "Perform the same exercise as the Tuck Back Lever Hold, and when in the horizontal position, increase the exercise's difficulty by opening your legs and lengthening them. This is the straddle position. Keep your neck in a neutral position, your body engaged and steady.",
    exerciseId: EXERCISE_KEYS.STRADDLE_BACK_LEVER,
    resourceUrl: "",
    contractionType: "isometric",
    icon: BackLeverIcon
  },
  [EXERCISE_KEYS.STRADDLE_DRAGON_FLAG]: {
    title: "Straddle Dragon Flag",
    description: (
      <p>
        The Straddle Dragon Flag is a regression for the{" "}
        <Link to="/exercises/dragon-flag">Dragon Flag</Link>. Lie down in a
        hollow body position with your legs spread apart. Using your core, pull
        your body upward until your weight is on your shoulders. Slowly bring
        your body back to the ground without breaking your hollow body.
        <Link to="/exercises/dragon-flag/">
          Learn how to perform the Dragon Flag and its regressions
        </Link>
        .
      </p>
    ),
    exerciseId: EXERCISE_KEYS.STRADDLE_DRAGON_FLAG,
    resourceUrl: "https://youtu.be/DJnX__TFB9o?t=64",
    contractionType: "isometric",
    icon: DragonFlagIcon
  },
  [EXERCISE_KEYS.STRADDLE_ELBOW_LEVER]: {
    title: "Straddle Elbow Lever",
    description:
      "Contrary to how it looks, achieving the movement is based more on body positioning rather than strength. Begin by placing hands flat on the ground with fingers facing outwards or behind you and move into a Push-up position. Keep a one foot distance between the hands. With the hands in this position, the elbows should be pointing directly upwards, so that as you lean your body forward on to your elbows, the elbows should push into either side of your belly button. Straddle your legs and slowly lift them off the floor. Keep your body leaning forward to maintain balance.",
    exerciseId: EXERCISE_KEYS.STRADDLE_ELBOW_LEVER,
    resourceUrl: "https://www.youtube.com/embed/ZNCFXIIB0eQ?start=92",
    contractionType: "isometric",
    icon: ElbowLeverIcon
  },
  [EXERCISE_KEYS.STRADDLE_FLAG]: {
    title: "Straddle Flag",
    description:
      "As you become more comfortable with the vertical flag, start to bring your body into a more horizontal position. The challenge will increase dramatically, and one way of mitigating the challenge is to straddle your legs, or to keep them tucked (the latter reduces the leverage and should be an easier intermediary exercise). Regardless of leg position, keep your body as horizontal as possible, ensuring that your form is correct by keeping your hips straight and chest level.",
    exerciseId: EXERCISE_KEYS.STRADDLE_FLAG,
    resourceUrl: "",
    contractionType: "isometric",
    icon: HumanFlagIcon
  },
  [EXERCISE_KEYS.STRADDLE_FRONT_LEVER]: {
    title: "Straddle Front Lever",
    description: (
      <p>
        The Straddle Front Lever is a challenging regression for the Front
        Lever. It requires a lot of core and back strength. Using a Pull Up bar,
        keep your arms straight and perform the Straddle Front Lever by holding
        your body horizontal, with your chest facing upward, and your legs
        spread apart{" "}
        <Link to="/exercises/front-lever/">
          Learn how to perform the Front Lever and its regressions
        </Link>
        .
      </p>
    ),
    exerciseId: EXERCISE_KEYS.STRADDLE_FRONT_LEVER,
    resourceUrl: "",
    contractionType: "isometric",
    icon: FrontLeverIcon
  },
  [EXERCISE_KEYS.STRADDLE_FRONT_LEVER_ROW]: {
    title: "Straddle Front Lever Row",
    description:
      "Start the exercise in the Front Lever position. Begin the exercise in a straddle front lever and pull the bar towards your until it reaches your chest. Control the movement back to starting and repeat.",
    exerciseId: EXERCISE_KEYS.STRADDLE_FRONT_LEVER_ROW,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: FrontLeverIcon
  },
  [EXERCISE_KEYS.STRADDLE_ONE_ARMED_HANDSTAND]: {
    title: "Straddle One Armed Handstand",
    description:
      "Begin the exercise in the Tuck Handstand. Slowly straighten your legs into a straddled Handstand. Slowly shift your weight into your left arm, and reduce the weight on the right arm. Eventually lift your right arm off the ground. Hold the position then repeat for the other side.",
    exerciseId: EXERCISE_KEYS.STRADDLE_ONE_ARMED_HANDSTAND,
    resourceUrl: "https://youtu.be/UE9Tn08BZBA?t=61",
    contractionType: "isometric",
    icon: HandstandIcon
  },
  [EXERCISE_KEYS.STRADDLE_ONE_ARMED_ROW]: {
    title: "Straddle One Armed Row",
    description:
      "The same movement as the Ring Archer Row, performed with a single arm, as the straight arm is no longer used for support. Move your body into a position in between the Incline Row and Row starting positions and plant your legs slightly wider than shoulder-width apart. Perform a row by pulling the ring toward your chest, control the movement back to starting.",
    exerciseId: EXERCISE_KEYS.STRADDLE_ONE_ARMED_ROW,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: RowIcon
  },
  [EXERCISE_KEYS.STRADDLE_PLANCHE]: {
    title: "Straddle Planche",
    description:
      "Perform the beginnning of the exercise as you would a Tuck Planche. As your arms become straight, pull your knees apart, and slowly bring your legs outward until you reach a straddle position",
    exerciseId: EXERCISE_KEYS.STRADDLE_PLANCHE,
    resourceUrl: "",
    contractionType: "isometric",
    icon: PlancheIcon
  },
  [EXERCISE_KEYS.STRADDLE_PLANCHE_PUSH_UP]: {
    title: "Straddle Planche Push Up",
    description:
      "Follow the same instructions as the Tuck Planche. Perform the Straddle Planche on a pair of parallettes. When in the Planche, bring your arms down to 90 degrees. Once there, push down with your chest and triceps until your back up to starting.",
    exerciseId: EXERCISE_KEYS.STRADDLE_PLANCHE_PUSH_UP,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: PlancheIcon
  },
  [EXERCISE_KEYS.STRAIGHT_LEG_AB_WHEEL]: {
    title: "Straight Let Ab Wheel",
    description:
      "Perform the exercise similarly to the Kneeling Ab Wheel, but standing up. This is a much more challenging variation, and it's important for your core to remain engaged throughout. No sagging at the hips.",
    exerciseId: EXERCISE_KEYS.STRAIGHT_LEG_AB_WHEEL,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: PlankIcon
  },
  [EXERCISE_KEYS.TABLE_BRIDGE]: {
    title: "Table Bridge",
    description:
      "This exercise is the combination of the Reverse Plank and the Shoulder Bridge. Plant your feet directly under your knees and lean back so your hands are underneath your shoulders. Lift your butt into the air until your body is in a perfect table position.",
    exerciseId: EXERCISE_KEYS.TABLE_BRIDGE,
    resourceUrl: "",
    contractionType: "isometric",
    icon: WheelIcon
  },
  [EXERCISE_KEYS.TOES_TO_BAR]: {
    title: "Toes to Bar",
    description:
      "Hang from the bar and move into the hollow body position. Pull your legs up into the air, ensure that they're straight with toes pointed throughout. As your legs reach toward the bar try to keep your torso vertical, if you have the compression strength for it, otherwise lean back slightly. Let your toes touch the bar, and bring them back down to starting in a controlled manner.",
    exerciseId: EXERCISE_KEYS.TOES_TO_BAR,
    resourceUrl: "https://youtu.be/qcosxmOBQK0",
    contractionType: "isotonic",
    icon: ToesToBarIcon
  },
  [EXERCISE_KEYS.TUCK_BACK_LEVER]: {
    title: "Tuck Back Lever",
    description:
      "On a bar, move through the Tuck Skin the Cat. As you approach the German Hang and your body is horizontal, tighten your core and squeeze your grip. Hold this horizontal position, keeping knees and chin towards your chest.",
    exerciseId: EXERCISE_KEYS.TUCK_BACK_LEVER,
    resourceUrl: "",
    contractionType: "isometric",
    icon: BackLeverIcon
  },
  [EXERCISE_KEYS.TUCK_FRONT_LEVER]: {
    title: "Tuck Front Lever",
    description: (
      <p>
        The Tuck Front Lever is a regression for the Front Lever. It's both a
        core and back exercise. Using a Pull Up bar, keep your arms straight and
        perform the Tuck Front Lever by holding your body horizontal, with your
        chest facing upward, and your legs tucked as close to your chest as
        possible.{" "}
        <Link to="/exercises/front-lever/">
          Learn how to perform the Front Lever and its regressions
        </Link>
        .
      </p>
    ),

    exerciseId: EXERCISE_KEYS.TUCK_FRONT_LEVER,
    resourceUrl: "",
    contractionType: "isometric",
    icon: FrontLeverIcon
  },
  [EXERCISE_KEYS.TUCK_HANDSTAND]: {
    title: "Tuck Handstand",
    description:
      "When kicking up into a handstand, push up with both legs at once. Instead of straightening them out, keep them the legs bent. Once you've found your balance, bring the knees closer to the chest. Keeping this balanced requires your shoulders to learn further forward.",
    exerciseId: EXERCISE_KEYS.TUCK_HANDSTAND,
    resourceUrl: "https://www.youtube.com/watch?v=7LFkqv9I9XM",
    contractionType: "isometric",
    icon: HandstandIcon
  },
  [EXERCISE_KEYS.TUCK_HOLLOW_HOLD]: {
    title: "Tuck Hollow Hold",
    description:
      "Begin the exercise lying on your back. Bring your knees close to your chest and your arms behind you with your shoulders by your ears. Engage your core to bring your upper back off the ground. Ensure that your back is flat on the ground.",
    exerciseId: EXERCISE_KEYS.TUCK_HOLLOW_HOLD,
    resourceUrl: "https://youtu.be/miYSdh_FBB8?t=23",
    contractionType: "isometric",
    icon: HollowHoldIcon
  },
  [EXERCISE_KEYS.TUCK_ICE_CREAM_MAKER]: {
    title: "Tuck Ice Cream Maker",
    description:
      "Once you're in the Tuck Front Lever position, pull down on the bar with your arms to transition into a Tuck Pull-up. Ensure that your chin comes over the bar. With control, bring your body back into the Tuck Front Lever position.",
    exerciseId: EXERCISE_KEYS.TUCK_ICE_CREAM_MAKER,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: FrontLeverIcon
  },
  [EXERCISE_KEYS.TUCK_LSIT]: {
    title: "Tuck L-Sit",
    description:
      "Perform the Foot Supported L-sit, bring your legs as close to your chest as possible. Flex your knees so your body is a tight ball. Protract. When performing the movement, arms should be locked out, with shoulders retracted and depressed. The butt should be underneath the shoulders with the chest proud. ",
    exerciseId: EXERCISE_KEYS.TUCK_LSIT,
    resourceUrl: "",
    contractionType: "isometric",
    icon: LSitIcon
  },
  [EXERCISE_KEYS.TUCK_ONE_LEGGED_NORDIC_CURL]: {
    title: "Tuck One Legged Nordic Curl",
    description:
      "Perform the same exercise as the 45° Nordic Curl, but with only a single leg controlling the movement",
    exerciseId: EXERCISE_KEYS.TUCK_ONE_LEGGED_NORDIC_CURL,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: NordicCurlIcon
  },
  [EXERCISE_KEYS.TUCK_PLANCHE]: {
    title: "Tuck Planche",
    description:
      "Plant your hands on the ground, directly under your shoulders and keep your knees tucked as closely to your chest as possible. Protract your shoulders and shift the weight on to your hands by leaning forward, without using your arms to support your legs. Keep your hips high so that they're in line or above your head. ",
    exerciseId: EXERCISE_KEYS.TUCK_PLANCHE,
    resourceUrl: "",
    contractionType: "isometric",
    icon: PlancheIcon
  },
  [EXERCISE_KEYS.TUCK_PLANCHE_PUSH_UP]: {
    title: "Tuck Planche Push Up",
    description:
      "Perform the Tuck Planche on a pair of parallettes. This is important as you'll have a greater range of motion to perform the exercise. When in the Tuck Planche, bend your arms, and keep your elbows close to your body. When your elbows area at a right-angle, push back up to starting position.",
    exerciseId: EXERCISE_KEYS.TUCK_PLANCHE_PUSH_UP,
    resourceUrl: "https://youtu.be/f30rLg3yBug?t=263",
    contractionType: "isotonic",
    icon: PlancheIcon
  },
  [EXERCISE_KEYS.TUCK_SKIN_THE_CAT]: {
    title: "Tuck Skin the Cat",
    description:
      "Begin by performing the exercise in the same manner as the German Hang. Once your shoulders are at full extension, reverse the motion by lifting your hips and using your shoulders to move back into the starting hang position.",
    exerciseId: EXERCISE_KEYS.TUCK_SKIN_THE_CAT,
    resourceUrl: "https://www.youtube.com/embed/0g6tVBdfsUw?start=20",
    contractionType: "isotonic",
    icon: PikeSkinTheCatIcon
  },
  [EXERCISE_KEYS.TUCK_UP_CRUNCH]: {
    title: "Tuck Up Crunch",
    description:
      "Begin the exercise in a hollow body position but with your arms out to your side. Lift your torso off the ground without arching your back and pull in your knees as the same time, hold this position briefly. In a controlled manner, bring your legs and your torso back out to their starting positions.",
    exerciseId: EXERCISE_KEYS.TUCK_UP_CRUNCH,
    resourceUrl: "https://www.youtube.com/embed/jXhCVgNYDow",
    contractionType: "isotonic",
    icon: HollowHoldIcon
  },
  [EXERCISE_KEYS.TYPEWRITER_PULL_UP]: {
    title: "Typewriter Pull Up",
    description:
      "One of the most impressive looking Pull Up variations. Begin by performing the concentric phase of the Wide Pull Up. Once at the top of the bar, pull your body toward left arm. This should cause your right arm to straighten, Once your right arm is full straight, move your body back to center and repeat for the other side. Pull your body as close to your bent arm as possible.",
    exerciseId: EXERCISE_KEYS.TYPEWRITER_PULL_UP,
    resourceUrl: "https://www.youtube.com/watch?v=hDd1HuCGNPQ",
    contractionType: "isotonic",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.VERTICAL_FLAG]: {
    title: "Vertical Flag",
    description:
      "To move into this movement, get into your chamber hold position. As your hips reach their highest point, begin to stretch your legs directly upwards, try to keep your body vertical. Remember to pull using the higher arm, and to pull using the lower arm, and to keep them both straight. The further your legs are from the pole, the longer the leverage, meaning a more challenging exercise.",
    exerciseId: EXERCISE_KEYS.VERTICAL_FLAG,
    resourceUrl: "",
    contractionType: "isometric",
    icon: HumanFlagIcon
  },
  [EXERCISE_KEYS.VERTICAL_ROW]: {
    title: "Vertical Row",
    description:
      "Stand upright with a fixed bar placed at chest height. Grip the bar with your hands shoulder-width apart, and begin the exercise with your chest touching the bar. Lean back slowly, keeping your body aligned, controlling the movement with your upper back. Ensure your core is engaged and that your hips aren't sagging. When arms are fully extended, pull the bar towards you to bring yourself up to standing.",
    exerciseId: EXERCISE_KEYS.VERTICAL_ROW,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: RowIcon
  },
  [EXERCISE_KEYS.VERTICAL_PUSH_UP]: {
    title: "Vertical Push Up",
    description:
      "Stand upright against the wall and place your hands just lower than chest height and shoulder-width apart. Stand at a distance where your palms touch the wall and your arms are straight. With control, bend your arms and keep elbows close to your body, don't let them flare out. Bring your chest to the wall. Hold for a second, then push up against the wall to bring your body back to starting.",
    exerciseId: EXERCISE_KEYS.VERTICAL_ROW,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: PushUpIcon
  },
  [EXERCISE_KEYS.WALL_ASSISTED_HANDSTAND_PRESS]: {
    title: "Wall Assisted Handstand Press",
    description:
      "Begin the exercise as if you were about to kick up to a Wall Assisted Handstand. Your hands should begin about 6 inches away from the wall. As you begin the Handstand Press lean forward until your back makes contact with the wall. Lift your legs up into a Handstand while using the wall for assistance.",
    exerciseId: EXERCISE_KEYS.WALL_ASSISTED_HANDSTAND_PRESS,
    resourceUrl: "https://youtu.be/NQ7e2NxEJuM?t=31",
    contractionType: "isotonic",
    icon: WallHandstandIcon
  },
  [EXERCISE_KEYS.WALL_HANDSTAND]: {
    title: "Wall Handstand",
    description:
      "Place hands just in front of the wall. Lift your left leg up, and push up hard with your right leg. Practice the kick up movement until you're comfortable with the momentum needed to bring your legs above your head. Once your legs are above your body and leaning on the wall behind you, squeeze your legs together, tighten your glutes and engage your core. Hold this position. making sure that your shoulders are directly above your wrists. ",
    exerciseId: EXERCISE_KEYS.WALL_HANDSTAND,
    resourceUrl: "https://youtu.be/eFmjckKXEoA?t=123",
    contractionType: "isometric",
    icon: WallHandstandIcon
  },
  [EXERCISE_KEYS.WALL_HANDSTAND_PUSH_UP]: {
    title: "Wall Handstand Push Up",
    description:
      "This exercise is almost identical to the Wall Headstand Push Up, but with the addition of a deficit. This means using equipment like the parallettes so your head comes below your hands, and your shoulders nearly touch your hands.",
    exerciseId: EXERCISE_KEYS.WALL_HANDSTAND_PUSH_UP,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: WallHandstandIcon
  },
  [EXERCISE_KEYS.WALL_HEADSTAND_PUSH_UP]: {
    title: "Wall Headstand Push Up",
    description:
      "Perform the negative portion of the exercise exactly the same as with the negative version, but when your head reaches the floor, tighten your body and push down with your arms. Push your body up back to the starting position. Make sure that your form remains tight throughout.",
    exerciseId: EXERCISE_KEYS.WALL_HEADSTAND_PUSH_UP,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: WallHandstandIcon
  },
  [EXERCISE_KEYS.WALL_PLANK]: {
    title: "Wall Plank",
    description:
      "With a wall behind you, begin the exercise in a plank position, but place your feet on the wall. Ensure that your body is perfectly horizontal, and that your shoulders are directly above the rest. Treat this exercise like a Plank, and keep your core and glutes engaged throughout. Don't let the hips sink. To make this exercise more challenging, walk your legs up the wall and bring your body closer to the wall.",
    exerciseId: EXERCISE_KEYS.WALL_PLANK,
    resourceUrl: "https://youtu.be/9hNgDPtmExA?t=25",
    contractionType: "isometric",
    icon: PlankIcon
  },
  [EXERCISE_KEYS.WHEEL]: {
    title: "Wheel",
    description:
      "Begin the exercise lying down on the floor. Plant your feet near your glutes, as with the previous exercise. Frame your head with your hands and point your fingers behind your head. Push down with your hands until your shoulders come off of the ground. Push your hips up high so your body makes a wheel shape. Experiment with the position, but aim to bring your feet close to your body. When exiting the pose, reverse the movement, but tuck your chin to your chest.",
    exerciseId: EXERCISE_KEYS.WHEEL,
    resourceUrl: "https://youtu.be/iSGYRP6bXW8?t=215",
    contractionType: "isometric",
    icon: WheelIcon
  },
  [EXERCISE_KEYS.WIDE_PULL_UP]: {
    title: "Wide Pull Up",
    description: (
      <>
        <p>
          The Wide Grip Pull Up is performed similarly to the regular Pull Up.
          The main difference being the distance between the hands. Stretch your
          arms out to the side and point your hands toward the sky. This should
          roughly be the distance of your hands during the Pull Up. When
          performing the exercise, the same rules apply as the Pull Up.
        </p>
        <Link to="/exercises/pull-up/">Learn how to perform the Pull Up</Link>.
      </>
    ),
    exerciseId: EXERCISE_KEYS.WIDE_PULL_UP,
    resourceUrl: "https://www.youtube.com/watch?v=iywjqUo5nmU",
    contractionType: "isotonic",
    icon: PullUpIcon
  },
  [EXERCISE_KEYS.WIDE_ROW]: {
    title: "Wide Row",
    description:
      "Make the exercise more challenging by placing your hands in a wider grip. The movement remains the same as the row. For an added challenge to the row exercises, use gymnastic rings.",
    exerciseId: EXERCISE_KEYS.WIDE_ROW,
    resourceUrl: "",
    contractionType: "isotonic",
    icon: RowIcon
  }
};

export default exercises;
