import { State, Action } from "../models";

export function createReducer<T>() {
  function reducer(state: State<T>, action: Action<T>): State<T> {
    switch (action.type) {
      case "NOT_LOGGED_IN":
        return {
          loading: false,
          data: action.payload.data,
          error: null
        };
      case "REQUEST_SENT":
        return {
          loading: true,
          data: null,
          error: null
        };
      case "RESULT_RECEIVED":
        return {
          loading: false,
          data: action.payload.data,
          error: null
        };
      case "ERROR_RECEIVED":
        return {
          loading: false,
          data: null,
          error: Error(action.payload.error as string)
        };
      default:
        return state;
    }
  }

  return reducer;
}
