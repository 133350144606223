import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { Link } from "gatsby";
import Cookies from "universal-cookie";
import Button from "./Button";
import setCookies from "../helpers/setCookies";
import "../styles/CookieConsentForm.css";

function CookieConsentForm() {
  const cookies = new Cookies();
  const [displayState, setDisplayState] = useState(false);

  useEffect(() => {
    if (!cookies.get("accepted-cookie-consent-form")) {
      setDisplayState(true);
    }
  }, [displayState, cookies]);

  function handleAccept() {
    setCookies();
    setDisplayState(false);
  }

  return (
    <div
      data-testid="cookie-consent-form"
      className={classnames("CookieConsentForm", {
        "CookieConsentForm--hidden": !displayState
      })}
    >
      <p className="CookieConsentForm__copy">
        We use cookies to enhance the user experience.{" "}
        <span className="CookieConsentForm__fine-print">
          More details can be found in our{" "}
          <Link to="/privacy-policy">privacy policy</Link>.
        </span>
      </p>
      <div className="CookieConsentForm__button-container">
        <Button handleClick={handleAccept} size="small">
          I accept
        </Button>
      </div>
    </div>
  );
}

export default CookieConsentForm;
