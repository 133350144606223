export { default as CloseIcon } from "./close.svg";
export { default as CloseIconWhite } from "./close-white.svg";
export { default as ComputerIcon } from "./computer.svg";
export { default as CrossIcon } from "./cross.svg";
export { default as DesktopIcon } from "./desktop.svg";
export { default as FemaleSymbolIcon } from "./female-symbol.svg";
export { default as GhostIcon } from "./ghost.svg";
export { default as LockIcon } from "./lock.svg";
export { default as MaleSymbolIcon } from "./male-symbol.svg";
export { default as MobileIcon } from "./mobile.svg";
export { default as PlanningIcon } from "./planning.svg";
export { default as RingsIcon } from "./rings.svg";
export { default as RulerIcon } from "./ruler.svg";
export { default as TickIcon } from "./tick.svg";
export { default as TreeIcon } from "./tree.svg";
export { default as TrophyIcon } from "./trophy.svg";
