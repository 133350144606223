function getUser(userId: string, accessToken: string) {
  return fetch(`${process.env.GATSBY_SERVER_URL}/users/${userId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  });
}

export default getUser;
