import useAuth from "../hooks/useAuth";
import { useEffectOnce } from "react-use";

function TokenRenewal() {
  const { renewToken, tokenRenewalComplete } = useAuth();

  useEffectOnce(() => {
    // the callback page handles it's own tokenRenewalLogic
    if (window.location.href.includes("callback")) {
      return;
    }

    if (!tokenRenewalComplete) {
      renewToken();
    }
  });

  return null;
}

export default TokenRenewal;
