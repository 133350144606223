import ReactGA from "react-ga";
import Cookies from "universal-cookie";

function setCookies() {
  const cookies = new Cookies();

  cookies.set("accepted-cookie-consent-form", true);
  ReactGA.set({ allowAdFeatures: true });
}

export default setCookies;
