enum EXERCISE_KEYS {
  PLANK = "plank",
  ONE_ARMED_PLANK = "oa-plank",
  KNEES_AB_WHEEL = "knees-ab-wheel",
  STRAIGHT_LEG_AB_WHEEL = "sl-ab-wheel",
  REV_HYPER_EXTENSION = "rev-hyperext",
  HYPER_EXTENSION = "hyperext",
  ARCH_BODY_HOLD = "arch-body-hold",
  BANDED_PALOFF_PRESS = "banded-paloff",
  KNEE_COPENHAGEN_PLANK = "knee-copenhagen",
  SIDE_PLANK = "side-plank",
  COPENHAGEN_PLANK = "copenhagen-plank",
  MOVING_COPENHAGEN_PLANK = "copenhagen-plank-move",
  TUCK_HOLLOW_HOLD = "tuck-hollow-hold",
  HOLLOW_HOLD = "hollow-hold",
  TUCK_UP_CRUNCH = "tuck-up-crunch",
  SEATED_PIKE_LEG_LIFT = "seated-pike-leg-lift",
  HANGING_KNEE_RAISE = "hanging-knee-raise",
  TOES_TO_BAR = "toes-to-bar",
  NEG_TUCK_DRAGON_FLAG = "tuck-df-neg",
  STRADDLE_DRAGON_FLAG = "str-df",
  DRAGON_FLAG = "df",
  GERMAN_HANG = "german-hang",
  TUCK_SKIN_THE_CAT = "tuck-skin-the-cat",
  PIKE_SKIN_THE_CAT = "pike-skin-the-cat",
  TUCK_BACK_LEVER = "tuck-back-lever",
  STRADDLE_BACK_LEVER = "str-back-lever",
  BACK_LEVER = "back-lever",
  BACK_LEVER_NEGATIVE = "back-lever-negative",
  VERTICAL_ROW = "vertical-row",
  INCLINE_ROW = "incline-row",
  ROW = "row",
  WIDE_ROW = "wide-row",
  RING_ARCHER_ROW = "ring-archer-row",
  STRADDLE_ONE_ARMED_ROW = "str-oa-row",
  ONE_ARMED_ROW = "oa-row",
  TUCK_FRONT_LEVER = "tuck-front-lever",
  STRADDLE_FRONT_LEVER = "str-front-lever",
  FRONT_LEVER_RAISES = "front-lever-raises",
  FRONT_LEVER_NEGATIVES = "front-lever-negatives",
  FRONT_LEVER = "front-lever",
  TUCK_ICE_CREAM_MAKER = "tuck-icm",
  STRADDLE_FRONT_LEVER_ROW = "str-front-lever-row",
  FRONT_LEVER_ROW = "front-lever-row",
  INCLINE_PUSH_UP = "incline-push-up",
  PUSH_UP = "push-up",
  DIAMOND_PUSH_UP = "diamond-push-up",
  PSEUDO_PLANCHE_PUSH_UP = "pp-push-up",
  PIKE_PUSH_UP = "pike-up",
  ARCHER_PUSH_UP = "archer-push-up",
  RTO_PUSH_UP = "rto-push-up",
  RTO_ARCHER_PUSH_UP = "rto-archer-push-up",
  RING_FLYES = "ring-flyes",
  RTO_MALTESE_PUSH_UPS = "rto-maltese-push-up",
  PLANCHE_LEAN = "planche-lean",
  CROW_POSE = "crow-pose",
  CRANE_POSE = "crane-pose",
  TUCK_PLANCHE = "tuck-planche",
  STRADDLE_PLANCHE = "str-planche",
  PLANCHE = "planche",
  TUCK_PLANCHE_PUSH_UP = "tuck-planche-pu",
  STRADDLE_PLANCHE_PUSH_UP = "str-planche-pu",
  PLANCHE_PUSH_UP = "planche-pu",
  STRADDLE_ELBOW_LEVER = "str-elbow-lever",
  ELBOW_LEVER = "elbow-lever",
  SCAPULAR_PULL = "scapular-pull",
  ARCH_HANG = "arch-hang",
  NEG_PULL_UP = "pull-up-neg",
  PULL_UP = "pull-up",
  LSIT_PULL_UP = "lsit-pull-up",
  PULLOVER = "pullover",
  ARCHER_PULL_UP = "archer-pull-up",
  CHEST_TO_BAR_PULL_UP = "c2b-pull-up",
  NEG_MUSCLE_UP = "mu-neg",
  MUSCLE_UP = "mu",
  CHAMBER_HOLD = "chamber-hold",
  VERTICAL_FLAG = "vertical-flag",
  STRADDLE_FLAG = "straddle-flag",
  HUMAN_FLAG = "human-flag",
  FOOT_SUPPORTED_LSIT = "foot-sup-lsit",
  TUCK_LSIT = "tuck-lsit",
  LSIT = "lsit",
  FORTY_FIVE_DEG_VSIT = "45-deg-vsit",
  NINETY_DEG_VSIT = "90-deg-vsit",
  ONE_TWENTY_VSIT = "120-deg-vsit",
  MANNA = "manna",
  BAR_SUPPORT_HOLD = "bar-sup-hold",
  NEG_BAR_DIP = "bar-dip-neg",
  BAR_DIP = "bar-dip",
  LSIT_DIP = "lsit-dip",
  RUSSIAN_DIP = "russian-dip",
  RING_SUPPORT_HOLD = "ring-support-hold",
  NEG_RING_DIP = "ring-dip-neg",
  RING_DIP = "ring-dip",
  RTO_RING_DIP = "ring-dip-rto",
  BULGARIAN_DIP = "bulgarian-dip",
  WALL_PLANK = "wall-plank",
  WALL_HANDSTAND = "wall-hs",
  HANDSTAND = "handstand",
  NEG_WALL_HEADSTAND_PUSH_UP = "wall-hes-pu-neg",
  WALL_HEADSTAND_PUSH_UP = "wall-hes-pu",
  WALL_HANDSTAND_PUSH_UP = "wall-hs-pu",
  HEADSTAND = "headstand",
  HEADSTAND_PUSH_UP = "hes-pu",
  HANDSTAND_PUSH_UP = "hs-pu",
  RING_SHOULDER_STAND = "ring-ss",
  RING_HANDSTAND = "ring-hs",
  TUCK_HANDSTAND = "tuck-hs",
  STRADDLE_ONE_ARMED_HANDSTAND = "straddle-oa-hs",
  ONE_ARMED_HANDSTAND = "oa-hs",
  REVERSE_PLANK = "reverse-plank",
  SHOULDER_BRIDGE = "shoulder-bridge",
  TABLE_BRIDGE = "table-bridge",
  WHEEL = "wheel",
  ASSISTED_SQUAT = "ass-squat",
  PARALLEL_SQUAT = "parallel-squat",
  FULL_SQUAT = "full-squat",
  COSSACK_SQUAT = "cossack-squat",
  ASSISTED_PISTOL_SQUAT = "ass-pistol-squat",
  PISTOL_SQUAT = "pistol-squat",
  SPLIT_SQUAT = "split-squat",
  BULGARIAN_SPLIT_SQUAT = "bulgarian-split-squat",
  DEEP_STEP_UP = "deep-step-up",
  BEGINNER_SHRIMP_SQUAT = "beg-shrimp-squat",
  SHRIMP_SQUAT = "shrimp-squat",
  ROMANIAN_DEADLIFT = "romanian-deadlift",
  ONE_LEGGED_DEADLIFT = "ol-deadlift",
  FORTY_FIVE_DEG_NORDIC_CURL = "45deg-hip-nc",
  NEG_NORDIC_CURL = "nordic-curl-negative",
  NORDIC_CURL = "nordic-curl",
  TUCK_ONE_LEGGED_NORDIC_CURL = "tuck-ol-nordic-curl",
  ONE_LEGGED_NORDIC_CURL = "ol-nordic-curl",
  JEFFERSON_CURLS = "jefferson-curls",
  PRESS_WALKS = "press-walks",
  HEADSTAND_LEG_RAISE = "headstand-leg-raise",
  HANDSTAND_LEAN_HOLDS = "handstand-lean-holds",
  HEIGHT_ASSISTED_HANDSTAND_PRESS = "height-assisted-handstand-press",
  WALL_ASSISTED_HANDSTAND_PRESS = "wall-assisted-handstand-press",
  HANDSTAND_PRESS = "handstand-press",
  RING_ARCHER_PUSH_UP = "ring-archer-push-up",
  NEG_OA_PUSH_UP = "neg-oa-push-up",
  ROCKY_PUSH_UP = "rocky-push-up",
  OA_PUSH_UP = "oa-push-up",
  RING_OA_PUSH_UP = "ring-oa-push-up",
  WIDE_PULL_UP = "wide-pull-up",
  OA_SCAPULAR_PULL = "oa-scapular-pull",
  TYPEWRITER_PULL_UP = "typewriter-pull-up",
  BAND_ASSISTED_OA_PULL_UP = "band-assisted-pull-up",
  RING_ARCHER_PULL_UP = "ring-archer-pull-up",
  NEG_OA_PULL_UP = "neg-oa-pull-up",
  OA_PULL_UP = "oa-pull-up",
  RING_ROWS = "ring-rows",
  VERTICAL_PUSH_UP = "vertical-pu",
  KNEES_TO_CHEST = "knees-to-chest",
  INVERTED_HANG = "inverted-hang",
  EXTENDED_PLANK = "extended-plank"
}

export default EXERCISE_KEYS;
