import React from "react";
import ReactGA from "react-ga";
import Cookies from "universal-cookie";
import { useEffectOnce } from "react-use";

function GDPRComplianceCheck() {
  const cookies = new Cookies();

  useEffectOnce(() => {
    if (cookies.get("accepted-cookie-consent-form")) {
      ReactGA.set({ allowAdFeatures: true });
    }
  });

  return <div />;
}

export default GDPRComplianceCheck;
