import React, { createContext, useState } from "react";

interface ISheetContext {
  isSheetVisible: boolean;
  setSheetVisibility: (isVisible: boolean) => void;
}

interface Props {
  children: React.ReactNode;
}

const SheetContext = createContext<ISheetContext>({
  isSheetVisible: false,
  setSheetVisibility: () => null
});

export function SheetProvider({ children }: Props) {
  const [isSheetVisible, setSheetVisibility] = useState(false);

  return (
    <SheetContext.Provider
      value={{
        isSheetVisible,
        setSheetVisibility
      }}
    >
      <div>{children}</div>
    </SheetContext.Provider>
  );
}

export default SheetContext;
