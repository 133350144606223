import React from "react";
import cogoToast from "cogo-toast";
import Snackbar from "../components/base/Snackbar";

const DEFAULT_HIDE_AFTER = process.env.NODE_ENV === "test" ? 1 : 10;

interface Args {
  message: string;
  type: "info" | "success" | "error" | "loading" | "warn";
  hideAfter?: number;
  actionMessage?: string;
  handleAction?: () => void;
}

function generateSnackbar(config: Args) {
  const {
    message,
    type,
    hideAfter = DEFAULT_HIDE_AFTER,
    actionMessage,
    handleAction
  } = config;

  const { hide } = cogoToast[type](
    <Snackbar
      message={message}
      actionMessage={actionMessage}
      handleAction={handleAction}
      handleClose={() => {
        return hide && hide();
      }}
    />,
    {
      position: "bottom-center",
      hideAfter
    }
  );

  return null;
}

export default generateSnackbar;
