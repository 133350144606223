import React, { createContext } from "react";
import ReactGA from "react-ga";
import auth0, { Auth0UserProfile, WebAuth, Auth0DecodedHash } from "auth0-js";
import { navigate } from "gatsby";
import { CreateUserResponse } from "../models/database";
import setCookies from "../helpers/setCookies";
import getUserByEmail from "../api/getUserByEmail";
import createUser from "../api/createUser";
import updateLastLoginTime from "../api/updateLastLoginTime";

const REDIRECT_ON_LOGIN = "redirect_on_login";

interface Props {
  children: React.ReactNode;
}

interface State {
  tokenRenewalComplete: boolean;
}

interface IAuthContext {
  tokenRenewalComplete: boolean;
  getAuthenticationState: () => boolean;
  login: (pathname: string) => void;
  logout: () => void;
  handleAuthentication: () => void;
  renewToken: () => void;
  getProfile: () => Auth0UserProfile | null;
  getUserId: () => string;
  getAccessToken: () => string;
}

export const defaultAuthContextValues = {
  tokenRenewalComplete: false,
  getAuthenticationState: () => false,
  login: () => null,
  logout: () => null,
  handleAuthentication: () => null,
  renewToken: () => null,
  getProfile: () => null,
  getUserId: () => "",
  getAccessToken: () => ""
};

const AuthContext = createContext<IAuthContext>(defaultAuthContextValues);

export class AuthProvider extends React.Component<Props, State> {
  auth0: WebAuth;

  private userProfile: Auth0UserProfile | null;
  private expiresAt: number = Date.now();
  private accessToken: string = "";
  private userId: string | null = null;

  constructor(props: Props) {
    super(props);

    this.userProfile = null;
    this.auth0 = new auth0.WebAuth({
      responseType: "token id_token",
      scope: "profile email openid",
      domain: process.env.GATSBY_AUTH_DOMAIN,
      clientID: process.env.GATSBY_AUTH_CLIENT_ID,
      redirectUri: process.env.GATSBY_AUTH_REDIRECT_URI,
      audience: process.env.GATSBY_AUTH_AUDIENCE
    });

    this.state = {
      tokenRenewalComplete: false
    };
  }

  getAuthenticationState = () => {
    return this.expiresAt > new Date().getTime();
  };

  login = (pathname: string) => {
    this.trackAuthEvent("Requested log-in", "User is attempting to log-in");

    localStorage.setItem(REDIRECT_ON_LOGIN, pathname);
    this.auth0.authorize();
  };

  logout = () => {
    this.trackAuthEvent("Requested log-out", "User has logged-out");

    this.auth0.logout({
      clientID: process.env.GATSBY_AUTH_CLIENT_ID,
      returnTo: process.env.GATSBY_ROOT_URL
    });
  };

  getUserId = () => {
    if (!this.userId) {
      throw new Error("No id associated with this user");
    }

    return this.userId;
  };

  handleAuthentication = () => {
    // this issue where the app hangs looks like it happens because this gets called twice
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.handleSuccessfulAuthentication(authResult);
      } else if (err) {
        this.trackAuthEvent(
          "Failed to log-in",
          "There was an error logging the user in"
        );

        const description =
          err.errorDescription || err.error_description || err.description;

        console.error("error: ", description);

        localStorage.removeItem(REDIRECT_ON_LOGIN);
        navigate("/home/");
      }
    });
  };

  renewToken = () => {
    if (navigator.onLine) {
      this.auth0.checkSession({}, async (err, authResult) => {
        if (err) {
          // ignore this specific error on account of how silent auth works
          if (err.code !== "login_required") {
            console.error(err);
          }

          return this.setState({
            tokenRenewalComplete: true
          });
        }

        await this.setSession(authResult);

        return this.setState({
          tokenRenewalComplete: true
        });
      });
      window.removeEventListener("online", this.renewToken);
    } else {
      window.addEventListener("online", this.renewToken);
      return this.setState({
        tokenRenewalComplete: true
      });
    }
  };

  getProfile = () => {
    return this.userProfile;
  };

  setProfile = (defaultAccessToken?: string) => {
    return new Promise<Auth0UserProfile>((resolve, reject) => {
      if (this.userProfile) {
        return resolve(this.userProfile);
      }

      const accessToken = defaultAccessToken
        ? defaultAccessToken
        : this.getAccessToken();

      this.auth0.client.userInfo(accessToken, (err, profile) => {
        if (err) {
          return reject(err);
        }

        this.userProfile = profile;

        if (profile) {
          return resolve(this.userProfile);
        }
      });
    });
  };

  getAccessToken = () => {
    if (!this.accessToken) {
      throw new Error("No access token found!");
    }

    return this.accessToken;
  };

  private handleSuccessfulAuthentication = async (
    authResult: Auth0DecodedHash
  ) => {
    const storedRedirectUrl = localStorage.getItem(REDIRECT_ON_LOGIN);
    const redirectTo =
      storedRedirectUrl === null ? "/home/" : storedRedirectUrl;
    await this.setSession(authResult);

    this.setState({
      tokenRenewalComplete: true
    });

    this.trackAuthEvent(
      "Successfully logged-in",
      "User has logged-in to the application"
    );

    navigate(redirectTo);
    localStorage.removeItem(REDIRECT_ON_LOGIN);
  };

  private handleInitialLogin = async (accessToken: string) => {
    const body = {
      name: this.userProfile!.name,
      email: this.userProfile!.email
    };

    const res: CreateUserResponse = await createUser(accessToken, body);

    if (res.status === 400) {
      return console.error("There was an error creating a user");
    }

    this.trackAuthEvent(
      "Successfully signed-up",
      "User has successfully signed up to an account"
    );

    const { userId } = await res.json();

    this.userId = userId;
    updateLastLoginTime(userId, accessToken!);
    ReactGA.set({ userId });
  };

  private setSession = async (authResult: Auth0DecodedHash) => {
    try {
      const { accessToken } = authResult;
      const profile = await this.setProfile(accessToken);

      const body = {
        email: profile!.email
      };

      try {
        const userByEmail = await getUserByEmail(body, accessToken);
        if (userByEmail.status !== 404) {
          const { user_id: userId } = await userByEmail.json();
          this.userId = userId;
          updateLastLoginTime(userId, accessToken!);
          ReactGA.set({ userId });
        }

        if (userByEmail.status === 404) {
          await this.handleInitialLogin(accessToken!);
        }
      } catch (err) {
        console.error("err", err.message);
      }

      this.expiresAt = authResult.expiresIn! * 1000 + new Date().getTime();
      this.accessToken = authResult.accessToken!;

      setCookies();
      this.scheduleTokenRenewal();
    } catch (err) {
      console.error(err, "error setting user session");
      this.trackAuthEvent(
        "Error setting user session",
        "Unable to to retrieve use data from Auth0"
      );
    }
  };

  private scheduleTokenRenewal = () => {
    const delay = this.expiresAt - Date.now();

    if (delay > 0) {
      setTimeout(() => {
        this.renewToken();
      }, delay);
    }
  };

  private trackAuthEvent = (action: string, label: string) => {
    ReactGA.event({
      category: "Authentication",
      label,
      action
    });
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          tokenRenewalComplete: this.state.tokenRenewalComplete,
          getAuthenticationState: this.getAuthenticationState,
          login: this.login,
          logout: this.logout,
          handleAuthentication: this.handleAuthentication,
          renewToken: this.renewToken,
          getProfile: this.getProfile,
          getUserId: this.getUserId,
          getAccessToken: this.getAccessToken
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthContext;
