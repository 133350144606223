import React from "react";
import "../styles/LinkButton.css";

interface Props {
  children: React.ReactNode;
  handleClick: VoidFunction;
}

function LinkButton({ children, handleClick }: Props) {
  return (
    <button
      className="LinkButton"
      aria-label="Link Button"
      onClick={handleClick}
    >
      <p className="LinkButton__text">{children}</p>
    </button>
  );
}

export default LinkButton;
