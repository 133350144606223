import React, { createContext, useState } from 'react';

interface IPromptContext {
  isPromptVisible: boolean;
  setPromptVisibility: (isVisible: boolean) => void;
}

interface PromptProviderProps {
  children: React.ReactNode;
}

const PromptContext = createContext<IPromptContext>({
  isPromptVisible: false,
  setPromptVisibility: () => null,
})

export function PromptProvider({ children }: PromptProviderProps) {
  const [isPromptVisible, setPromptVisibility] = useState(false);

  return (
    <PromptContext.Provider value={{
      isPromptVisible,
      setPromptVisibility,
    }}>
      {children}
    </PromptContext.Provider>
  )
}

export default PromptContext;