// @ts-nocheck
export { default as BackLeverIcon } from "./back-lever.svg";
export { default as BarSupportHoldIcon } from "./bar-support-hold.svg";
export { default as CossackSquat } from "./cossack-squat.svg";
export { default as CrowPoseIcon } from "./crow-pose.svg";
export { default as DeepStepUpIcon } from "./deep-step-up.svg";
export { default as DragonFlagIcon } from "./dragon-flag.svg";
export { default as ElbowLeverIcon } from "./elbow-lever.svg";
export { default as FrontLeverIcon } from "./front-lever.svg";
export { default as HandstandIcon } from "./handstand.svg";
export { default as HollowHoldIcon } from "./hollow-hold.svg";
export { default as HumanFlagIcon } from "./human-flag.svg";
export { default as LSitIcon } from "./l-sit.svg";
export { default as MuscleUpIcon } from "./muscle-up.svg";
export { default as NordicCurlIcon } from "./nordic-curl.svg";
export { default as ParallelBarDipIcon } from "./parallel-bar-dip.svg";
export { default as ParallelSquatIcon } from "./parallel-squat.svg";
export { default as PikePushUpIcon } from "./pike-push-up.svg";
export { default as PikeSkinTheCatIcon } from "./pike-skin-the-cat.svg";
export { default as PistolSquatIcon } from "./pistol-squat.svg";
export { default as PlancheIcon } from "./planche.svg";
export { default as PlankIcon } from "./plank.svg";
export { default as PullUpIcon } from "./pull-up.svg";
export { default as PushUpIcon } from "./push-up.svg";
export { default as RowIcon } from "./row.svg";
export { default as SquatIcon } from "./squat.svg";
export { default as ToesToBarIcon } from "./toes-to-bar.svg";
export { default as WallHandstandIcon } from "./wall-handstand.svg";
export { default as WheelIcon } from "./wheel.svg";
