function updateLastLoginTime(userId: string, accessToken: string) {
  return fetch(
    `${process.env.GATSBY_SERVER_URL}/users/${userId}/update-login-time`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      }
    }
  );
}

export default updateLastLoginTime;
