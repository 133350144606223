import React from "react";
import "../styles/Icon.css";

export interface Props {
  handleClick?: () => void;
  containerWidth: number;
  src: string;
  title: string;
}

function Icon({ src, title, containerWidth, handleClick }: Props) {
  return (
    <div
      onKeyDown={e => {
        if (e.keyCode === 13 && handleClick) {
          handleClick();
        }
      }}
      tabIndex={handleClick ? 0 : -1}
      onClick={handleClick}
      data-testid="icon-container"
      style={{
        height: `${containerWidth}px`,
        width: `${containerWidth}px`,
        display: "flex",
        cursor: handleClick ? "pointer" : "default"
      }}
    >
      <img src={src} alt={title} className="Icon" />
    </div>
  );
}

export default Icon;
