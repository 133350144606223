interface Body {
  email?: string;
}

function getUserByEmail(body: Body, accessToken?: string) {
  return fetch(`${process.env.GATSBY_SERVER_URL}/users-by-email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(body)
  });
}

export default getUserByEmail;
