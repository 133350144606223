import React from "react";
import { Helmet } from "react-helmet";

function HTMLHead() {
  return (
    <Helmet>
      <script type="text/javascript">{`window.$crisp=[];window.CRISP_WEBSITE_ID="725ba073-a9e6-41b1-b139-b8becf1ba4bb";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`}</script>
    </Helmet>
  );
}

export default HTMLHead;
