import React from "react";
import ReactGA from "react-ga";
import { CloseIcon } from "../../icons";
import Icon from "../Icon";
import LinkButton from "../LinkButton";
import "../../styles/Snackbar.css";

interface Props {
  handleAction?: () => void;
  handleClose: () => void;
  actionMessage?: string;
  message: string;
}

function Snackbar(props: Props) {
  const { handleAction, actionMessage, message, handleClose } = props;

  function closeSnackbar() {
    ReactGA.event({
      category: "Application",
      action: "Manually closed snackbar",
      label: `User has declined snackbar with message: ${message}`
    });

    handleClose();
  }

  return (
    <div role="alert" className="Snackbar__content">
      <p className="Snackbar__message">{message}</p>
      <div className="Snackbar__action">
        {handleAction && (
          <LinkButton
            handleClick={() => {
              closeSnackbar();
              handleAction();
            }}
          >
            {actionMessage}
          </LinkButton>
        )}
        <button
          onClick={closeSnackbar}
          className="Snackbar__close"
          data-testid="snackbar-close-button"
        >
          <Icon title="Close Icon" containerWidth={24} src={CloseIcon} />
        </button>
      </div>
    </div>
  );
}

export default Snackbar;
